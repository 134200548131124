export const ZERO = 0;
export const ONE = 1;
export const TWO = 2;
export const THREE = 3;
export const FOUR = 4;
export const FIVE = 5;
export const SIX = 6;
export const SEVEN = 7;
export const EIGHT = 8;
export const TEN = 10;
export const ELEVEN = 11;
export const TWELVE = 12;
export const FOURTEEN = 14;
export const NINETEEN = 19;
export const TWENTY = 20;
export const THIRTYFIVE = 35;
export const FOURTY = 40;
export const SIXTY = 60;
export const SIXTYFIVE = 65;
export const HUNDRED = 100;
export const THREEHUNDRED = 300;
export const THOUSAND = 1000;