import React from "react";
import LoadingImage from "../../../../asset/Image/loading.svg";
import "./_index.css";

class Loader extends React.Component<{
    isLoading?: boolean;
}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const { isLoading = false } = this.props;
        return (
            <>
                {isLoading && (
                    <>
                        <div className="storybook--loader-overlay"></div>
                        <div className="storybook--loader-container">
                            <img src={LoadingImage} alt="Loading..." />
                        </div>
                    </>
                )}
            </>
        );
    }
}

export default Loader;
