import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { RootState } from "../../../../redux/store";

import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { addLanguage} from "../../../../redux/reducers/sessionSlice";
import { setLanguage } from "../../../../redux/thunks/sessionThunk";
import { MenuItem } from "../MenuItem";
import Icon from "../Icon";
import MainLogin from "../../../../asset/Image/logos/main-logo.png";
import "./_index.css";

const KEEPSESSION = "KEEPSESSION";
declare var window: any;

const LANGUAGE = [
    {
        shortDesc: "label.sidemenu.spanish",
        mnemonic: "ES",
    },
    {
        shortDesc: "label.sidemenu.english",
        mnemonic: "EN",
    },
];

interface SidenavProps {
    /**
     * disable left arrow?
     */
    showSideMenu?: boolean;
    hideLogo?: boolean;

    onClick?: (isClosed: boolean) => void;
}

/**
 * Primary UI component for user interaction
 */
export const Sidenav = ({ showSideMenu, hideLogo, onClick = () => {}, ...props }: SidenavProps) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { i18n, t } = useTranslation();
    const { token, language, isExpireSession, intervalIDexpireSession } = useSelector(
        (state: RootState) => state.session,
    );
    const [isSidenavOpen, setIsSidenavOpen] = useState(showSideMenu);
    const [appVersion, setAppVersion] = useState("1.0");

    const toggleSidenav = () => {
        setIsSidenavOpen(!isSidenavOpen);
    };

    const redirect = (to: string) => {
        history.push(to);
    };

    // Add a click event listener to the document when the sidenav is open
    useEffect(() => {
        if (isSidenavOpen) {
            const closeSidenavOnClickOutside = (event: any) => {
                if (!event.target.closest(".storybook--sidenav") && isSidenavOpen) {
                    toggleSidenav(); // Close the sidenav if the click is outside
                    onClick(false);
                }
            };

            document.addEventListener("click", closeSidenavOnClickOutside);

            return () => {
                document.removeEventListener("click", closeSidenavOnClickOutside);
            };
        }
    }, [isSidenavOpen, showSideMenu]);

    useEffect(() => {
        setIsSidenavOpen(showSideMenu);
    }, [showSideMenu]);


    const handleChangeLanguage = (changedLanguage: string) => {
        changedLanguage = changedLanguage.toLocaleLowerCase();
        setLanguage(changedLanguage);
        i18n.changeLanguage(changedLanguage);
        dispatch(addLanguage({ language: changedLanguage }));
    };

    useEffect(() => {
        const fetchAppVersion = async () => {
            if (window.cordova && window.cordova.getAppVersion) {
                try {
                    const appVersion = await window.cordova.getAppVersion.getVersionNumber();
                    setAppVersion(appVersion);
                } catch (error) {
                    console.error("Error obteniendo la versión de la aplicación:", error);
                }
            }
        };

        document.addEventListener("deviceready", fetchAppVersion);

        return () => {
            document.removeEventListener("deviceready", fetchAppVersion);
        };
    }, []);
    return (
        <>
            {isSidenavOpen && <div className="storybook--modal-overlay"></div>}
            <div className={`storybook--sidenav ${isSidenavOpen ? "open" : "closed"}`}>
                <div
                    className={[
                        "storybook--center-elements",
                        "storybook--sidenav-header",
                        "storybook--header-main-logo-container",
                        `storybook--header-main-logo-container-unlogged`,
                    ].join(" ")}>
                    {!hideLogo && <img className={"home-image-logo"} src={MainLogin} />}
                    <Icon icon="icon:x" onClick={() => onClick(false)} />
                </div>
                <div className="storybook--sidenav-menu-options">
                    {!token && (
                        <>
                            <MenuItem label="Quiénes somos" labelPosition="right" onClick={() => redirect("/aboutUs")} />
                            <MenuItem
                                label="Paquetes"
                                labelPosition="right"
                                onClick={() => {
                                    const section = document.getElementById("paquetes");
                                    if (section) {
                                        section.scrollIntoView({ behavior: "smooth" });
                                        onClick(false);
                                    }
                                }}
                            />
                            <MenuItem label="Contacto" labelPosition="right" onClick={() => {
                                    const section = document.getElementById("contacto");
                                    if (section) {
                                        section.scrollIntoView({ behavior: "smooth" });
                                        onClick(false);
                                    }
                                }} />
                            <MenuItem label="Preguntas frecuentes" labelPosition="right" onClick={() => redirect("/faq")} />
                        </>
                    )}

                    {/*<MenuItem icon="icon:video" label="Opcion 2" labelPosition="right" />
                    <MenuItem icon="icon:wrench" label="Opcion 3" labelPosition="right" />
                    <MenuItem icon="icon:comment-plus" label="Opcion 4" labelPosition="right" />
                    <MenuItem icon="icon:trees" label="Opcion 5" labelPosition="right" />*/}
                </div>
                {/*<RadioButtonGroup
                    name="language"
                    title="label.sidemenu.language"
                    radioButtonList={LANGUAGE}
                    defaultValue={language.toLocaleUpperCase()}
                    value={language.toUpperCase()}
                    onClick={(value) => handleChangeLanguage(value)}
                />*/}
            </div>
        </>
    );
};
