import * as yup from "yup";

export const homeInformationFormInitValues = {
    email: ""
};

export const homeInformationFormSchema = (t:any) => { 
    return yup.object().shape({
    email: yup.string().min(1, "").email("message.email.notvalid.error").required(t("message.email.required.error")),
});

}