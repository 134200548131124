import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alias: "",
    email: "",
    isLoading: false,
    birthdate: "",
    names: "",
    surnames: "",
    found: false,
    recovered: false,
    secondFactorValidated: false,
    token: "",
};

export const recoverySlice = createSlice({
    name: "recovery",
    initialState,
    reducers: {
        addUser: (state, action) => {
            const { alias, birthdate, email, names, surnames } = action.payload;
            state.alias = alias;
            state.birthdate = birthdate;
            state.email = email;
            state.names = names;
            state.surnames = surnames;
            state.found = true;
            state.isLoading = false;
        },
        addToken: (state, action) => {
            const { token } = action.payload;
            state.token = token;
            state.secondFactorValidated = true;
        },
        isFound: (state, action) => {
            const { found } = action.payload;
            state.found = found;
        },
        isRecovered: (state, action) => {
            const { recovered } = action.payload;
            state.recovered = recovered;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        clear: (state, action) => {
            state.alias = "";
            state.email = "";
            state.isLoading = false;
            state.birthdate = "";
            state.names = "";
            state.surnames = "";
            state.found = false;
            state.recovered = false;
            state.secondFactorValidated= false;
            state.token = "";
        },
    },
});

export const { addUser, addToken, clear, isFound, isRecovered, startLoading, stopLoading } = recoverySlice.actions;

export default recoverySlice.reducer;
