import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    create: {
        idReservation: "",
        field: "",
        reservationDay: "",
        fromHour: "",
        toHour: "",
        paymentType: {},
        cardNumber: "",
        customerName: "",
        expiricyDate: "",
        cvv: "",
        amount: "",
        appliedDate: "",
        receiptNumber: "",
        selectedProduct: {},
        isSuccessful: false,
        isError: false,
        finalEstatusPayment:"",
    },
    timer: 600,
    isLoading: false,
    reservations: [],
    reservationsPayment: [],
    isCleanReservation:true,
};

export const reservationSlice = createSlice({
    name: "reservation",
    initialState,
    reducers: {
        addFormStep1: (state, action) => {
            const { field, reservationDay, fromHour, toHour, selectedProduct } = action.payload;
            state.create.field = field;
            state.create.reservationDay = reservationDay;
            state.create.fromHour = fromHour;
            state.create.toHour = toHour;
            state.create.selectedProduct = selectedProduct;
        },
        addFormStep2: (state, action) => {
            const { amount, paymentType, customerName, appliedDate, receiptNumber, cardNumber, cvv, expiricyDate } = action.payload;
            state.create.paymentType = paymentType;
            state.create.amount = amount;
            state.create.customerName = customerName;
            state.create.appliedDate = appliedDate;
            state.create.receiptNumber = receiptNumber;
            state.create.cardNumber = cardNumber;
            state.create.cvv = cvv;
            state.create.expiricyDate = expiricyDate;
        },
        addBlockScheduleReservation: (state, action) => {
            const { idReservation } = action.payload;
            state.isLoading = false;
            state.create.idReservation = idReservation;
        },
        addTimer: (state, action) => {
            const { time } = action.payload;
            state.timer = time;
        },
        deleteIdReservation: (state, action) => {
            state.create.idReservation = "";
        },
        isError: (state, action) => {
            state.isLoading = false;
            state.create.isError = true;
            state.create.isSuccessful = false;
        },
        isSuccessfull: (state, action) => {
            state.isLoading = false;
            state.create.isSuccessful = true;
            state.create.isError = false;
            const { estatus } = action.payload;
            state.create.finalEstatusPayment =estatus;
        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        listReservations: (state, action) => {
            const { reservations } = action.payload;
            state.reservations = reservations;
        },
        cleanListReservation: (state, action) => {
            state.reservations= initialState.reservations;
        },
        listReservationsPayment: (state, action) => {
            const { reservationsPayment } = action.payload;
            state.reservationsPayment = reservationsPayment;
        },
        cleanListReservationPayment: (state, action) => {
            state.reservationsPayment= initialState.reservationsPayment;
        },
        cleanReservation:(state, action)=>{
            const { isCleanReservation } = action.payload;
            state.isCleanReservation = isCleanReservation;
        }
    },
});

export const {
    addTimer,
    clean,
    startLoading,
    stopLoading,
    deleteIdReservation,
    addFormStep1,
    addFormStep2,
    addBlockScheduleReservation,
    isError,
    isSuccessfull,
    listReservations,
    cleanListReservation,
    listReservationsPayment,
    cleanListReservationPayment,
    cleanReservation,
} = reservationSlice.actions;
export default reservationSlice.reducer;
