import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: "",
    language: "es",
    error:{mensaje:"",codigo:"",level:"",isError:false},
    isExpireSession:false,
    timerExpireSession:0,
    intervalIDexpireSession:-1,
};

export const sessionSlice = createSlice({
    name: "session",
    initialState,
    reducers: {
        addToken: (state, action) => {
            const { token } = action.payload;
            state.token = token;
        },
        addLanguage: (state, action) => {
            const { language } = action.payload;
            state.language = language;
        },
        closeSession: (state, action) => {
            state.token = "";
        },       
        addError:(state, action) => {
            const { error } = action.payload;
            state.error = error;
        },
        clearError:(state, action) => {
            const  error  = {...initialState.error};
            state.error = error;
        },
        expireSession:(state, action) => {
            const { isExpireSession } = action.payload;
            state.isExpireSession = isExpireSession;
            state.intervalIDexpireSession = -1;
        },
        setTimerExpireSession:(state, action) => {
            const { timerExpireSession,intervalIDexpireSession } = action.payload;
            state.isExpireSession = timerExpireSession;
            state.intervalIDexpireSession = intervalIDexpireSession;
        }
    },
});

export const { addToken, addLanguage, closeSession,addError,clearError,expireSession,setTimerExpireSession} = sessionSlice.actions;
export default sessionSlice.reducer;
