import React, { useEffect } from "react";

/*Components */
import { Header } from "../../react-components/src/Elements/Header";
import { withTranslation } from "react-i18next";
import { Slider } from "../../react-components/src/Elements/Slider";
import app from "../../asset/Image/app.png";
import slider from "../../asset/Image/slider1.jpg";
import { handleScroll, handleScroll2,handleScroll3,handleScroll4 } from "./scroll-animation";
/*styles*/
import "./_index.css";
import { Divider } from "../../react-components/src/Elements/Divider";
import { HomeFooter } from "./Components/HomeFooter";
import { HomePackages } from "./Components/HomePackages";
import { HomeBenefits } from "./Components/HomeBenefits";
import { HomeImages } from "./Components/HomeImages";
import { Text } from "../../react-components/src/Elements/Text";

const Home = () => {

    useEffect(() => {
        document.addEventListener("scroll", handleScroll);
        document.addEventListener("scroll", handleScroll2);
        document.addEventListener("scroll", handleScroll3);
        document.addEventListener("scroll", handleScroll4);
    }, []);
    return (
        <>
            {/*<Slider />*/}
            <div className="header--color-container" style={{backgroundImage:`url(${slider})`, backgroundRepeat:"no-repeat", backgroundSize: "cover", backgroundPosition:"center"}}>
                <div className="header--color-container-items storybook--center-elements storybook--margin-top-sm">
                    <Text label="Juega más, reserva fácil, disfruta sin complicaciones" align="right" color="color-beta" tag="h1" className="home-title storybook--margin-top-sm"/>
                    <img src={app} alt="Imagen de app" className="hide-image"/>
                </div>
            </div>
            <Header label="" className="storybook--center-elements home--header" isHome disableLeftArrow />
            <HomeImages />
            <Divider color="color-gamma" className="storybook--margin-left-md storybook--margin-right-md"/>
            <HomeBenefits />
            <Divider color="color-gamma" className="storybook--margin-left-md storybook--margin-right-md"/>
            <HomePackages />
            <Divider color="color-phi" className="storybook--margin-left-md storybook--margin-right-md"/>
            <HomeFooter />
        </>
    );
};

export default withTranslation()(Home);
