import React from "react";

import { Card } from "../../../react-components/src/Elements/Card";
import Icon from "../../../react-components/src/Elements/Icon";
import { Text } from "../../../react-components/src/Elements/Text";

/*styles*/
import "../_index.css";

export const HomeBenefits = () => {
    return (
        <>
            <Text
                    label="La mejor experiencia para tus jugadores"
                    align="center"
                    tag="h1"
                    className="storybook--margin-bottom-md storybook--color-phi text--line-height-md"
                />
                <div
                    className={[
                        "storybook--center-elements",
                        "storybook--container-flex-space-between",
                        "storybook--margin-bottom-md home--benefits-container",
                    ].join(" ")}>
                    <Card backgroundColor="color-beta">
                        <div className="storybook--center-elements storybook--margin-bottom-sm">
                            <Icon icon={"icon:user"} iconColor={"color-gamma"} size="medium" />
                        </div>
                        <Text
                            label="Regístrate de forma rápida y sencilla para disfrutar de todas las funciones de la app."
                            align="center"
                            color="color-alpha"
                            tag="b1"
                        />
                    </Card>
                    <Card backgroundColor="color-beta">
                        <div className="storybook--center-elements storybook--margin-bottom-sm">
                            <Icon icon={"icon:calendar"} iconColor={"color-gamma"} size="medium" />
                        </div>
                        <Text
                            label="Reserva y gestiona las Mejengas de manera sencilla con nuestra función de calendarización"
                            align="center"
                            color="color-alpha"
                            tag="b1"
                        />
                    </Card>
                    <Card backgroundColor="color-beta">
                        <div className="storybook--center-elements storybook--margin-bottom-sm">
                            <Icon icon={"icon:football"} iconColor={"color-gamma"} size="medium" />
                        </div>
                        <Text
                            label="Crea y gestiona tus equipos de fútbol de manera fácil, organizando jugadores, partidos y calificaciones en un solo lugar."
                            align="center"
                            color="color-alpha"
                            tag="b1"
                        />
                    </Card>
                    <Card backgroundColor="color-beta">
                        <div className="storybook--center-elements storybook--margin-bottom-sm">
                            <Icon icon={"icon:money"} iconColor={"color-gamma"} size="medium" />
                        </div>
                        <Text
                            label="Paga tus reservas de manera fácil y segura con múltiples opciones de pago disponibles."
                            align="center"
                            color="color-alpha"
                            tag="b1"
                        />
                    </Card>
                    <Card backgroundColor="color-beta">
                        <div className="storybook--center-elements storybook--margin-bottom-sm">
                            <Icon icon={"icon:headphones"} iconColor={"color-gamma"} size="medium" />
                        </div>
                        <Text
                            label="Disfruta de soporte 24/7, siempre disponible para ayudarte con cualquier duda o inconveniente"
                            align="center"
                            color="color-alpha"
                            tag="b1"
                        />
                    </Card>
                </div>
        </>
    );
}