import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alias: "",
    birthdate: "",
    email: "",
    isLoading: false,
    names: "",
    surnames: "",
    password: "",
    created: false,
    registered: false,
    isAliasValid: true,
};

export const registerSlice = createSlice({
    name: "register",
    initialState,
    reducers: {
        addInformation: (state, action) => {
            const { names, email, alias, birthdate, surnames, password } = action.payload;
            state.alias = alias;
            state.birthdate = birthdate;
            state.email = email;
            state.names = names;
            state.surnames = surnames;
            state.password = password;
        },
        isCreated: (state, action) => {
            const { created } = action.payload;
            state.created = created;
            state.isLoading = false;
        },
        isRegistered: (state, action) => {
            const { registered } = action.payload;
            state.registered = registered;
            state.isLoading = false;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        clear: (state, action) => {
            state.alias = "";
            state.birthdate = "";
            state.email = "";
            state.isLoading = false;
            state.names = "";
            state.surnames = "";
            state.password = "";
            state.created = false;
            state.registered = false;
        },
        updateAliasValid: (state, action) => {
            const { isAliasValid } = action.payload;
            state.isAliasValid = isAliasValid;
        }
    },
});

export const { addInformation, clear, isCreated, isRegistered, startLoading, stopLoading, updateAliasValid } =
    registerSlice.actions;

export default registerSlice.reducer;
