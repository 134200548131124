import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import {
    insertEnviromentVariable,
    deleteEnviromentVariable,
} from "./database/databaseDB";
import { closeSession, expireSession } from "./redux/reducers/sessionSlice";
import Home from "./pages/Home";
import TermsAndConditions from "./pages/termsAndConditions";
import FAQ from "./pages/faq/index";
import PrivacyPolicy from "./pages/PrivacyPolicy/index";
import AboutUs from "./pages/aboutUs/index";
import Partners from "./pages/partners/index";

import NotAvailable from "./pages/notAvailable/NotAvailable";
import NotFound from "./pages/notFound/NotFound";
import Onboarding from "./react-components/src/Elements/Onboarding";
import "./components/SplashScreen/SplashScreen.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import "../src/asset/css/all.css";
import "./App.css";

/*Components */
import Modal from "./react-components/src/Elements/Modal";
import { Button } from "./react-components/src/Elements/Button";

const ONBOARDING = "ONBOARDING";
const KEEPSESSION = "KEEPSESSION";
const SHOWN = "SHOWN";

const App = () => {
    const dispatch = useDispatch();
    const [isAppInitialized, setIsAppInitialized] = useState(false);
    const [showOnboarding, setShowOnboarding] = useState(false);
    const [basedatos, setBaseDatos] = useState(null);
    const { isExpireSession, intervalIDexpireSession } = useSelector((state: RootState) => state.session);

    useEffect(() => {
        const isSplashShown = localStorage.getItem("splashShown");

        if (isSplashShown) {
            initializeApp();
        } else {
            showSplashScreen();
        }
    }, []);

    const goToHome = () => {
        dispatch(closeSession({}));
        dispatch(expireSession({ isExpireSession: false }));
        clearInterval(intervalIDexpireSession);
        deleteEnviromentVariable(basedatos, KEEPSESSION);
    };

    const initializeApp = () => {
        setIsAppInitialized(true);
        // Replace with your actual initialization time
    };

    const showSplashScreen = () => {
        // Set the "splashShown" flag in localStorage
        localStorage.setItem("splashShown", "true");
        // Show the splash screen
        initializeApp();
    };

    const handleFinishOnboarding = () => {
        setShowOnboarding(false);
        insertEnviromentVariable(basedatos, ONBOARDING, SHOWN);
    };

    return (
        <div className="App">
            {
                showOnboarding ? (
                    <Onboarding onFinish={handleFinishOnboarding} />
                ) : (
                    <>
                        <BrowserRouter>
                            <Switch>
                                <Route exact path="/aboutUs" component={AboutUs} />
                                <Route exact path="/termsConditions" component={TermsAndConditions} />
                                <Route exact path="/faq" component={FAQ} />
                                <Route exact path="/privacyPolicy" component={PrivacyPolicy} />
                                <Route exact path="/partners" component={Partners} />
                                <Route exact path="/no-available" component={NotAvailable} />
                                <Route exact path="*" component={Home} />
                                <Route component={NotFound} />
                            </Switch>
                        </BrowserRouter>
                        {isExpireSession === true && (
                            <Modal
                                mode="error"
                                title="general.sessionTimeOut.text.title"
                                icon="icon:check-circle"
                                description={`general.sessionTimeOut.description`}>
                                <Button
                                    label="ui.button.accept"
                                    mode="primary"
                                    onClick={() => {
                                        goToHome();
                                    }}
                                />
                            </Modal>
                        )}
                    </>
                )}
        </div>
    );
};

export default App;
