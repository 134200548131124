import React from "react";

import { Text } from "../../Text";
import { Button } from "../../Button";
import { Image } from "../../Image";

const Step3 = ({onFinish}:any) => {
    return (
        <div>
            <Text tag="h2" label="onboarding..swiper.step3.title.text" align="center"/>
            <div className="storybook--center-elements">
                <Image imageRoute="image:children-play" className={["swiper--image3"].join(" ")} />
            </div>
            <Text tag="b2" label="onboarding..swiper.step3.description.text" align="center" />
        </div>
    );
};

export default Step3;
