import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    create: {},
    isLoading: false,
    isSuccess:false,
    isOpenCalification:false,
};


export const calificationPlatformSlice = createSlice({
    name: "calificationPlatform",
    initialState,
    reducers: { 
        openCalification: (state, action) => {
            state.isOpenCalification = true;
        },
        isSuccess: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;
        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        closeCalification: (state, action) => {
            state.isOpenCalification = false;
        },
    },
});

export const {
    clean,
    startLoading,
    stopLoading,
    isSuccess,
    openCalification,
    closeCalification,
} = calificationPlatformSlice.actions;
export default calificationPlatformSlice.reducer;