import config from "../support/config";

export const constantes = {
    url:`${config.apiBaseUrl}`,
    canal: "WEB",
    canal_APP: "APP",
    listaPerfiles: ["CLIENTE_BASICO_COMPRADOR","CLIENTE_BASICO_VENDEDOR"],
    menuIzquierdo: "MENU_IZQUIERDO",
    menuBarraSuperior: "MENU_BARRA_SUPERIOR",
    estadoActivo: "ACTIVO",
    estadoInactivo: "INACTIVO",
    defaultCantidadRegistrosPorPagina: 20,
    pageUsuarioPaginacion: 10,
    pageUsuarioPaginacionActivar:true,
    estatusOKAServ:"OKA",
    noRequireAutorizacionToken:['/public/WEB/auth','/public/WEB/register',
    '/moneda','/contenido','/parametroSistema','/usuario/WEB/activarUsuario',
    '/enviarLinkResetPassword','/usuario/reseteoPass','/carritoCompra','/contenidoHtml/data'],
    baseURLEnviarLinkResetPAssword:"/enviarLinkResetPassword",
    patternPhone:"^[+][(][0-9]{2,3}[)]([0-9]{4})[-]([0-9]{4})$",
    placeholderPhone:"+(58)1203-1231",
    axiosConfig : {
        headers: {
            'Access-Control-Allow-Origin' : '*',
            'Access-Control-Allow-Methods':'GET,PUT,POST,DELETE,PATCH,OPTIONS',
        }
    },
    sessionUser:"web-aplication-qasys-datosUsuario",
    sessionOperador:"web-aplication-qasys-datosOperador",
    sessionToken:"web-aplication-qasys-tokenSession",
    sessionTokenTemp:"web-aplication-qasys-tokenSessionTemporal",
    sessionI18n:"web-aplication-qasys-i18n",
    sessionI18nLanguague:"web-aplication-qasys-languague",
    sessionI18nLanguagueDefault:"es",
    defaultValorMultimedia:"IMAGEN",
    cantidadMaximaRegistrosMultimedia:5,
    tipoIdentificacion:[{codigo:"CEDULA",nombre:"Cédula"},{codigo:"DIMEX",nombre:"Dimex"},{codigo:"PASAPORTE",nombre:"Pasaporte"}],
    tipoIdentificacionVendedor:[{codigo:"CEDULA",nombre:"Cédula"},{codigo:"DIMEX",nombre:"Dimex"},{codigo:"PASAPORTE",nombre:"Pasaporte"},{codigo:"CEDULA_JURIDICA",nombre:"Cédula Juridica"}],
    genero:[{codigo:"MASCULINO",nombre:"Masculino"},{codigo:"FEMENINO",nombre:"Femenino"},{codigo:"INDEFINIDO",nombre:"Indefinido"}],
    formatoFecha:"dd-MM-yyyy",
    edadMinimaPersona:13,
    activarSegundoFactor:[{codigo:"true",nombre:"Si"},{codigo:"false",nombre:"No"}],
    tipoMenu:[{codigo:"EXTERNO",nombre:"EXTERNO"},{codigo:"INTERNO",nombre:"INTERNO"},{codigo:"MENU_IZQUIERDO",nombre:"MENU_IZQUIERDO"},{codigo:"MENU_BARRA_SUPERIOR",nombre:"MENU_BARRA_SUPERIOR"}],
    seleccionTrueFalse:[{codigo:"true",nombre:"Si"},{codigo:"false",nombre:"No"}],
    seleccionEstatus:[{codigo:"ACTIVO",nombre:"ACTIVO"},{codigo:"INACTIVO",nombre:"INACTIVO"}],
    seleccionTipoMultimedia:[{codigo:"IMAGEN",nombre:"IMAGEN"},{codigo:"VIDEO",nombre:"VIDEO"}],
    tipoTransaccion:[{codigo:"COBRO_MEMBRESIA_VENDEDOR",nombre:"COBRO MEMBRESIA VENDEDOR"},{codigo:"COBRO_PEDIDO",nombre:"COBRO PEDIDO"},{codigo:"PAGO_COLABORADOR",nombre:"PAGO COLABORADOR"},{codigo:"PAGO_VENDEDOR",nombre:"PAGO VENDEDOR"}],
    tipoContenido:[{codigo:"TOP_CALIFICADO",nombre:"TOP_CALIFICADO"},{codigo:"TOP_VENTA",nombre:"TOP_VENTA"},
                   {codigo:"TOP_MAS_GUSTADO",nombre:"TOP_MAS_GUSTADO"},
                   {codigo:"OFERTA_TEMPORADA",nombre:"OFERTA_TEMPORADA"},
                   {codigo:"MEJOR_CALIDAD",nombre:"MEJOR_CALIDAD"},
                   {codigo:"BANNER_CARRUSEL",nombre:"BANNER_CARRUSEL"},
                   {codigo:"BANNER_DELIVERY",nombre:"BANNER_DELIVERY"},
                   {codigo:"ULTIMAS_NOTICIAS",nombre:"ULTIMAS_NOTICIAS"},
                   {codigo:"TESTIMONIOS",nombre:"TESTIMONIOS"},
                   {codigo:"MARCAS_ALIADOS",nombre:"MARCAS_ALIADOS"},
                   {codigo:"PRODUCTO_ESPECIAL",nombre:"PRODUCTO_ESPECIAL"},
                   {codigo:"GRAN_OFERTA",nombre:"GRAN_OFERTA"},
                   {codigo:"MEJOR_VENDEDOR",nombre:"MEJOR_VENDEDOR"},
                   {codigo:"OFERTA_50",nombre:"OFERTA_50"},
                   {codigo:"OFERTA_45",nombre:"OFERTA_45"},
                   {codigo:"OFERTA_60",nombre:"OFERTA_60"},
                   {codigo:"SUSCRIPCION_NOTICIA",nombre:"SUSCRIPCION_NOTICIA"},
                   {codigo:"PIE_PAGINA_COPYRIGTH",nombre:"PIE_PAGINA_COPYRIGTH"},
                   ],
    variableSeccionContenido:"SECCION_PAGINA",
    variableSeccionFooterInformacion:"FOOTER_INFORMACION",
    variableSeccionCopyRigthInformacion:"COPY_RIGTH_INFORMACION",
    paginacionCustomerTestimonio:3,
    nombreSistema:"system.nombre.plataforma.base",
    metodosPagoPiePAgina:"PIE_PAGINA_COPYRIGTH.METODOS_PAGO",
    variableSeccionCoopyRigth:"system.nombre.plataforma.base,PIE_PAGINA_COPYRIGTH.METODOS_PAGO",
    variableSeccionFooterInformation:"FOOTER_INFORMACION.TEXTO,FOOTER_INFORMACION.TELEFONO,FOOTER_INFORMACION.DIRECCION,FOOTER_INFORMACION.CORREO",
    footerTexto:"FOOTER_INFORMACION.TEXTO",
    footerTelefono:"FOOTER_INFORMACION.TELEFONO",
    footerDireccion:"FOOTER_INFORMACION.DIRECCION",
    footerCorreo:"FOOTER_INFORMACION.CORREO",
    idiomas:["es","en"],
    defaultFavoritosPagination:200,
    defaultFavoritosRow:0,
    defaultPedidoProgramadoPagination:200,
    defaultPedidoProgramadoRow:0,
    tipoRecurrenciaPedido:[
        {codigo:"SEMANAL",nombre:"SEMANAL"},
        {codigo:"QUINCENAL",nombre:"QUINCENAL"},
        {codigo:"MENSUAL",nombre:"MENSUAL"}
        ],
};
