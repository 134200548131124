import React from "react";
import { ReactSVG } from "react-svg";
import { getColor } from "../../../../asset/themes";
import { getIcon } from "./../../../../asset";
import { Text } from "../Text";
import "./_index.css";

class Icon extends React.Component<{
    icon: string;
    iconColor?: string;
    label?: string;
    labelPosition?: string;
    name?: string;
    size?: "big" | "medium" | "small" | "big-small";
    className?: string;
    id?: string;
    onClick?: () => void;
}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {
            icon,
            iconColor = "color-rho",
            name,
            label = "",
            labelPosition = "bottom",
            size = "small",
            onClick,
            className,
            id="",
        } = this.props;
        let iconPath = require("./../../../../asset/" + getIcon(icon)); //eslint-disable-line
        return (
            <div id={id} className={["storybook--svg-wrapper", `storybook--svg-wrapper-${name}`].join(" ")}>
                {(size === "small") && (
                    <div
                        className={[
                            "icon-svg-container",
                            `icon-svg-container-label-position-${labelPosition}`,
                            className
                        ].join(" ")}>
                        <div onClick={onClick} role="button" tabIndex={0}>
                            <ReactSVG
                                src={iconPath}
                                wrapper="svg"
                                fill={getColor(iconColor)}
                                className={`icon-svg-container-${size}`}
                            />
                        </div>
                        {label && <Text label={label} tag={labelPosition ? "s2" : "s4"} align="left"/>}
                    </div>
                )}
                {(size === "big" || size === "medium" || size === "big-small") && (
                    <div
                        className={["icon-svg-container", `icon-svg-container-${size}`, className].join(" ")}
                        style={{
                            backgroundColor: `${getColor(iconColor)}`,
                            mask: `url(${iconPath}) no-repeat center / contain`,
                            WebkitMask: `url(${iconPath}) no-repeat center / contain`,
                        }}
                        onClick={onClick}
                        role="button"
                        tabIndex={0}></div>
                )}
            </div>
        );
    }
}

export default Icon;
