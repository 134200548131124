import React from "react";

import Icon from "../Icon";

import "./_index.css";
import { Text } from "../Text";

interface MenuItemProps {
    /**
     * change icon?
     */
    icon?: string;
    /**
     * add label?
     */
    label: string;
    /**
     * add label position?
     */
    labelPosition: string;

    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const MenuItem = ({ icon, label, labelPosition, onClick, ...props }: MenuItemProps) => {
    return (
        <>
            <div
                className={["storybook--center-elements", "storybook--menu-item"].join(" ")}
                onClick={onClick}>
                {icon && <Icon icon={icon} label={label} labelPosition={labelPosition} />}
                {!icon && <Text label={label} tag={labelPosition ? "s2" : "s4"} align="left"/>}
                <Icon icon="icon:chevron-right" />
            </div>
        </>
    );
};
