import React from 'react';

import { Text } from '../../Text';
import { Image } from "../../Image";

const Step1 = () => {
  return (
    <div>
      <div className="storybook--center-elements swiper--padding">
          <Image imageRoute="image:main-logo" className={["swiper--image"].join(" ")} />
      </div>
      <Text tag='h2' label='onboarding..swiper.step1.title.text' align='center'/>
      <Text className="swiper--padding"  tag='b2' label='onboarding..swiper.step1.description.text' align='center'/>
    </div>
  );
};

export default Step1;
