import React from "react";
import { getColor } from "./../../../../asset/themes";
import "./_index.css";

export interface CardProps {
    id?:string;
    /**
     * change backgroundColor?
     */
    backgroundColor?: string;
    /**
     * add className
     */
    className?: string;
    /**
     * Optional click handler
     */
    children?: any;
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Card = ({
    id,
    backgroundColor = "color-omega",
    children,
    className,
    ...props
}: CardProps) => {
    return (
        <div
            id={id}
            className={[`storybook--card`, className].join(" ")}
            style={{ backgroundColor: getColor(backgroundColor) }}
            {...props}>
            {children}
        </div>
    );
};
