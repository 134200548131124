import API from '../redux/thunks/api';

import {constantes}  from '../components/constantes';

export function getI18n(iso){
    return new Promise(function (resolve, reject) {
        API.get(`/traduccion/i18n/${iso}`, constantes.axiosConfig).then(
            (response) => {
                var result = response.data;
                resolve(result);
            },
                (error) => {
                reject(error);
            }
        );
    });
}