import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    code: "",
    isLoading: false,
    validated: false,
    notValid: false,
    flow: "",
};

export const secondFactorSlice = createSlice({
    name: "secondFactor",
    initialState,
    reducers: {
        addFlow: (state, action) => {
            const { flow } = action.payload;
            state.flow = flow;
        },
        isValidated: (state, action) => {
            const { validated } = action.payload;
            state.validated = validated;
            state.isLoading = false;
        },
        isNotValid: (state, action) => {
            const { isNotValid } = action.payload;
            state.notValid = isNotValid;
            state.isLoading = false;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        clear: (state, action) => {
            state.validated = false;
            state.flow = "";
            state.isLoading = false;
        },
    },
});

export const { addFlow, clear, isValidated, startLoading, stopLoading, isNotValid } = secondFactorSlice.actions;

export default secondFactorSlice.reducer;
