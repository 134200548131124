import React from "react";

import { Text } from "../Text";
import { getColor } from "./../../../../asset/themes";
import { getIcon } from "./../../../../asset";
import "./_index.css";

interface ButtonProps {
    /**
     * Button mode
     */
    mode?: string; //"primary" | "primary-outline" | "primary-unoutline" | "disabled" | "disabled-outline" | "danger-outline";

    /**
     * How large should the button be?
     */
    size?: "chip" | "small" | "large"; // "small" | "medium" | "large"
    /**
     * Button contents
     */
    label: string;
    labelTag?:  "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "s1" | "s2" | "s3" | "s4" | "b1" | "b2" | "b3" | "b4" | undefined;
    /**
     * icon src
     */
    icon?: string;
    /**
     * icon color
     */
    iconColor?: string;
    /**
     * className
     */
    className?: string;
    /**
     * isSubmit
     */
    isSubmit?: boolean;
    /**
     * isDisabled
     */
    isDisabled?: boolean;
    /**
     * text align
     */
    textAlign?: "left" | "center" | "right";
    /**
     * Optional click handler
     */
    onClick?: () => void | undefined | Promise<void>;
}

/**
 * Primary UI component for user interaction
 */
export const Button = ({
    mode = "primary",
    size = "large",
    label,
    labelTag = "h5",
    icon,
    iconColor,
    className,
    isSubmit,
    isDisabled,
    textAlign = "center",
    ...props
}: ButtonProps) => {
    let hasIcon = icon ? "storybook-button--icon storybook--center-elements" : "";
    let iconPath = require("./../../../../asset/" + getIcon(icon ? icon : "icon:arrow-left")); //eslint-disable-line
    return (
        <button
            type={isSubmit ? "submit" : "button"}
            className={[
                "storybook-button",
                `storybook-button--${size}`,
                `storybook-button--${mode}`,
                "storybook--center-elements",
                "storybook--container-flex-align-items-center",
                hasIcon,
                className,
            ].join(" ")}
            style={{ color: getColor(iconColor ? iconColor : "") }}
            disabled={isDisabled}
            {...props}>
            {icon && (
                <div
                    style={{
                        height: "1rem",
                        width: "1rem",
                        backgroundColor: `${getColor(iconColor ? iconColor : "color-alpha")}`,
                        mask: `url(${iconPath}) no-repeat center / cover`,
                        WebkitMask: `url(${iconPath}) no-repeat center / cover`,
                    }}
                    className="storybook--margin-right-xs"></div>
            )}
            {label && (
                <Text
                    label={label}
                    tag={labelTag ? labelTag : "h5"}
                    align={textAlign}
                    color={getColor(iconColor ? iconColor : "color-beta")}
                />
            )}
        </button>
    );
};
