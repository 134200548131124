const Icons: { [key: string]: any } = {
    "icon:alert-circle": "Image/icons/icn_alert-circle.svg",
    "icon:arrow-growth": "Image/icons/icn_arrow-growth.svg",
    "icon:arrow-down": "Image/icons/icn_arrow-down.svg",
    "icon:arrow-left": "Image/icons/icn_arrow-left.svg",
    "icon:arrow-up": "Image/icons/icn_arrow-up.svg",
    "icon:bell": "Image/icons/icn_bell.svg",
    "icon:calendar": "Image/icons/icn_calendar.svg",
    "icon:camera": "Image/icons/icn_camera.svg",
    "icon:check": "Image/icons/icn_check.svg",
    "icon:check-circle": "Image/icons/icn_check-circle.svg",
    "icon:check-square": "Image/icons/icn_check-square.svg",
    "icon:chevron-down": "Image/icons/icn_chevron-down.svg",
    "icon:chevron-up": "Image/icons/icn_chevron-up.svg",
    "icon:chevron-right": "Image/icons/icn_chevron-right.svg",
    "icon:clock-nine": "Image/icons/icn_clock-nine.svg",
    "icon:comment-plus": "Image/icons/icn_comment-plus.svg",
    "icon:create-dashboard": "Image/icons/icn_create-dashboard.svg",
    "icon:credit-card": "Image/icons/icn_credit-card.svg",
    "icon:drop": "Image/icons/icn_drop.svg",
    "icon:edit": "Image/icons/icn_edit.svg",
    "icon:eye-slash": "Image/icons/icn_eye-slash.svg",
    "icon:eye": "Image/icons/icn_eye.svg",
    "icon:football": "Image/icons/icn_football.svg",
    "icon:headphones": "Image/icons/icn_headphones.svg",
    "icon:home-alt": "Image/icons/icn_home-alt.svg",
    "icon:link": "Image/icons/icn_link.svg",
    "icon:log-out": "Image/icons/icn_log-out.svg",
    "icon:mastercard": "Image/icons/icn_mastercard.svg",
    "icon:mail": "Image/icons/icn_mail.svg",
    "icon:mail-success": "Image/icons/icn_mail-success.svg",
    "icon:map-marker": "Image/icons/icn_map-marker.svg",
    "icon:map-marker-generic": "Image/icons/icn_map-marker-generic.svg",
    "icon:map-pin": "Image/icons/icn_map-pin.svg",
    "icon:menu": "Image/icons/icn_menu.svg",
    "icon:minus-circle": "Image/icons/icn_minus-circle.svg",
    "icon:money": "Image/icons/icn_money.svg",
    "icon:money-bill": "Image/icons/icn_money-bill.svg",
    "icon:more-vertical": "Image/icons/icn_more-vertical.svg",
    "icon:plus": "Image/icons/icn_plus.svg",
    "icon:plus-circle": "Image/icons/icn_plus-circle.svg",
    "icon:question-circle": "Image/icons/icn_question-circle.svg",
    "icon:star": "Image/icons/icn_star.svg",
    "icon:search": "Image/icons/icn_search.svg",
    "icon:trash": "Image/icons/icn_trash.svg",
    "icon:trees": "Image/icons/icn_trees.svg",
    "icon:usd-square": "Image/icons/icn_usd-square.svg",
    "icon:user": "Image/icons/icn_user.svg",
    "icon:user-check": "Image/icons/icn_user-check.svg",
    "icon:user-check-success": "Image/icons/icn_user-check-success.svg",
    "icon:user-minus": "Image/icons/icn_user-minus.svg",
    "icon:user-plus": "Image/icons/icn_user-plus.svg",
    "icon:users-alt": "Image/icons/icn_users-alt.svg",
    "icon:utensils": "Image/icons/icn_utensils.svg",
    "icon:video": "Image/icons/icn_video.svg",
    "icon:visa": "Image/icons/icn_visa.svg",
    "icon:wrench": "Image/icons/icn_wrench.svg",
    "icon:x": "Image/icons/icn_x.svg",
    "icon:assistive-listening": "Image/icons/icn_assistive-listening-systems.svg",
    "icon:file-edit-alt": "Image/icons/icn_file-edit-alt.svg",
};

export const getIcon = (icon: string) => {
    return Icons[icon];
};


const Images: { [key: string]: any } = {
    "image:notification-empty": "Image/notification-empty.png",
    "image:mejenga-empty": "Image/tablero.png",
    "image:bota-balon": "Image/botaBalon.png",
    "image:main-logo": "Image/logos/main-logo.png",
    "image:children-play": "Image/children-play.png",
    "image:create-mejenga": "Image/create-mejenga.png",
}

export const getImage = (image: string) => {
    return Images[image];
};