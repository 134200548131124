import React from "react";

import Icon from "../Icon";
import { Card } from "../Card";
import { getModalColor } from "../../../../asset/themes";
import { Text } from "../Text";

import "./_index.css";

class Modal extends React.Component<{
    mode: "success" | "error" | "warning" | "default";
    icon?: string;
    title?: string;
    description?: string;
    children?: string | JSX.Element | JSX.Element[];
}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {  mode = "success", icon, title = "", description = "", children } = this.props;
        return (
            <>
                <div className="storybook--modal-overlay"></div>
                <div className={["storybook--modal-container"].join(" ")}>
                    <Card>
                        {icon && (
                            <div className="storybook--center-elements">
                                <Icon icon={icon} iconColor={getModalColor(mode)} size="medium" />
                            </div>
                        )}
                        <div className="storybook--modal-info-container">
                            <Text
                                label={title}
                                tag="h3"
                                align="center"
                                className="storybook--modal-info-title"
                            />
                            <Text
                                label={description}
                                tag="s2"
                                align="center"
                                className="storybook--modal-info-description"
                            />
                        </div>
                        {children}
                    </Card>
                </div>
            </>
        );
    }
}

export default Modal;
