import axios from "axios";
import config from "../../support/config";

export const API_URL = `${config.apiBaseUrl}`; 

const instance = axios.create({
    baseURL: API_URL,
});

instance.interceptors.request.use(
    function (config) {
        return config;
    },
    function (error) {
        return Promise.reject(error);
    },
);

instance.interceptors.response.use(
    function (response) {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        return response;
    },
    function (error) {

        if (error.response !== undefined && error.response.status === 403) {
            try {
                //dataDestroy();
            } catch (e) {
                //error
            }
            //refreshToken()
        }
        if (error.response !== undefined && error.response.status === 401) {
            try {
                //dataDestroy();
            } catch (e) {
                //error
            }
            // window.location.href = "/";
            //refreshToken()
        }

        if (error.response !== undefined && error.response.status === 404) {
            try {
                // dataDestroy();
            } catch (e) {
                //error
            }
            //window.location.href = "/";
            //refreshToken()
        }

        if (error.response !== undefined && error.response.status === 500) {
            //dataDestroy();
            window.location.href = "/no-available";
            //refreshToken()
        }

        return Promise.reject(error);
    },
);

export default instance;
