import { createSlice } from "@reduxjs/toolkit";
import { ZERO } from "../constants/numbers";

const initialState = {
    statistics: {
        average: ZERO,
        skills: [],
    },
    skillTypes: [],
    isLoading: false,
    isSuccess: false,
    isError: false,
};

export const calificationUserSlice = createSlice({
    name: "calificationUser",
    initialState,
    reducers: {
        addStatistics: (state, action) => {
            const { average, skills, skillTypes } = action.payload;
            state.statistics.average = average;
            state.statistics.skills = skills;
            state.skillTypes = skillTypes;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        isSendSuccess: (state, action) => {
            const { success } = action.payload;
            state.isSuccess = success;
        },
        isSendError: (state, action) => {
            const { error } = action.payload;
            state.isError = error;
        },
        clean: (state, action) => {
            return initialState;
        },
    },
});

export const {
    addStatistics,
    startLoading,
    stopLoading,
    isSendSuccess,
    isSendError,
    clean,
} = calificationUserSlice.actions;

export default calificationUserSlice.reducer;
