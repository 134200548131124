import { addLanguage } from "../reducers/sessionSlice";
import { constantes }  from '../../components/constantes';
import Cookies from 'js-cookie';

const mobStorage = sessionStorage;

export const getLanguage = () => {
    let datos  = mobStorage.getItem(constantes.sessionI18nLanguague);
    if(!datos){
        setLanguage(constantes.sessionI18nLanguagueDefault);
        datos = constantes.sessionI18nLanguagueDefault;
    }else{
        try {
            datos = JSON.parse(datos);
        } catch(er) {
            return datos;
        }
    }
    return datos;
}

export const setLanguage = (language: string) => {
    mobStorage.setItem(constantes.sessionI18nLanguague, language);
    addLanguage(language);    
}

export const getI18n = () => {
    const data = mobStorage.getItem(constantes.sessionI18n);
    if(data) return JSON.parse(data);
    return data;
}

export const setI18n = (data: any) => {
    let datos  = data ? data : "" ;
    mobStorage.setItem(constantes.sessionI18n, JSON.stringify(datos));
}

export const getCookie = (name: string) => {
    return Cookies.get(name);
}

export const setCookie = ({name, value}:any) => {
    Cookies.set(name, value, {secure: true});
}

export const deleteToken = (name: string) => {
    Cookies.remove(name);
}