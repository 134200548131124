import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    processUrl: "",
    requestId: "",
    isLoading: false,
    response: {
        status: ""
    },
};

export const paymentGatewaySlice = createSlice({
    name: "paymentGateway",
    initialState,
    reducers: {
        addProcessUrl: (state, action) => {
            const { requestId, processUrl } = action.payload;
            state.processUrl = processUrl;
            state.requestId = requestId;
        },
        addResponse: (state, action) => {
            const { response } = action.payload;
            state.response = response;
        },
        cleanSessionValues: (state, action) => {
            state.processUrl = "";
            state.requestId = "";
            state.isLoading = false;
        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
    },
});

export const { addProcessUrl, addResponse, clean,cleanSessionValues, startLoading, stopLoading } = paymentGatewaySlice.actions;
export default paymentGatewaySlice.reducer;
