export const openDatabase = () => {
    return new Promise((resolve, reject) => {
        document.addEventListener('deviceready', () => {
            const db = window.sqlitePlugin.openDatabase({ name: 'vamosfutbol.db', location: 'default' }, 
                () => resolve(db), 
                (error:any) => reject(error)
            );
        });
    });
};

export const createTableEnvironment = (db:any) => {
    db.transaction((tx:any) => {
        tx.executeSql(
            'CREATE TABLE IF NOT EXISTS Environment (variable TEXT, value TEXT)',
            [],
            () => {
                console.log('Table created successfully');
            },
            (error:any) => {
                console.error('Error creating table:', error.message);
            }
        );
    });
}

export const validateEnviromentVariable = (db:any, name: string) => {
    return new Promise((resolve, reject) => {
        db.transaction((tx:any) => {
            tx.executeSql(
                'SELECT * FROM Environment WHERE variable=?',
                [name],
                (tx:any, results:any) => resolve(results),
                (error:any) => reject(error.message)
            );
        });
    });
};

export const insertEnviromentVariable = (db:any, name:string, value: string) => {
    db.transaction((tx:any) => {
        tx.executeSql(
            'INSERT INTO Environment (variable, value) VALUES (?,?)',
            [name, value],
            () => {
                console.log('Session added successfully');
            },
            (error:any) => {
                console.error('Error adding session:', error.message);
            }
        );
    });
};

export const deleteEnviromentVariable = (db:any, name:string) => {
    db.transaction((tx:any) => {
        tx.executeSql(
            'DELETE FROM Environment WHERE variable = ?',
            [name],
            () => {
                console.log('Variable deleted successfully');
            },
            (error:any) => {
                console.error('Error deleting variable:', error.message);
            }
        );
    });
};
