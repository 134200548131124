import React, { useEffect, useState } from "react";
import { getIcon } from "../../../../../asset";
import { Text } from "../../Text";
import { Field, useField } from "formik";
import { useTranslation } from "react-i18next";
import "./_index.css";
import { getColor } from "../../../../../asset/themes";

const REQUIRED = " *";

const InputText = ({
    icon,
    label,
    id,
    required,
    maxLength,
    disabled,
    inputType,
    placeholder,
    hasError,
    errorMessage,
    onInput,
    pattern,
    initValue,
    onlyNumbers,
    readonly,
    className,
    as="input",
    regex,
    isOptional,
    ...props
}: any) => {
    const [field, meta] = useField(props);
    const { t } = useTranslation();
    const [value, setValue] = useState(initValue ? initValue : "");
    let iconPath = "";
    if (icon) iconPath = require("../../../../../asset/" + getIcon(icon)); //eslint-disable-line

    const handleChange = (event: any) => {
        const valueInput = event.target.value;
        let cleanedValue = valueInput;
        if (onlyNumbers) {
            cleanedValue = valueInput.replace(/\D/g, "");
        }
        if (regex) {
            cleanedValue = valueInput.replace(new RegExp(regex, "g"), "");
        }
        setValue(cleanedValue);

        if (onInput) {
            onInput(cleanedValue);
        }
    };

    useEffect(() => {
        if (readonly) {
            setValue(initValue);
        }
    }, [initValue]);

    return (
        <div className={"storybook--input-text-container"}>
            {label && (
                <div className={["storybook--input-label", className].join(" ")}>
                    <label htmlFor={id}>{t(label)}</label>
                    {required && <span>{REQUIRED}</span>}
                    {isOptional && <span className="storybook--margin-left-xs" style={{color: getColor("color-phi")}}>{"("+t("label.input.optional")+")"}</span>}
                </div>
            )}
            <div className={"storybook--input-group"}>
                <Field
                    as={as}
                    id={id}
                    className={[
                        "storybook--input",
                        `storybook--input-${inputType}-noarrow`,
                        `storybook--input-${inputType}-nocalendar`,
                        `${((meta.touched && meta.error) || (hasError && errorMessage)) ? "storybook--input-error" : ""}`,
                        `${!icon ? "storybook--padding-right-none" : ""}`,
                    ].join(" ")}
                    {...field}
                    {...props}
                    disabled={disabled}
                    maxLength={maxLength}
                    placeholder={t(placeholder)}
                    type={inputType}
                    onInput={(val: any) => {
                        val.preventDefault();
                        handleChange(val);
                    }}
                    style={
                        icon
                            ? {
                                  backgroundImage: `url(${iconPath})`,
                                  backgroundRepeat: "no-repeat",
                                  backgroundPosition: "97% center",
                              }
                            : {}
                    }
                    value={value}
                    readOnly={readonly}
                />
            </div>
            {((meta.touched && meta.error) || (hasError && errorMessage)) && (
                <Text
                    label={meta.touched && meta.error ? t(meta.error) : hasError && errorMessage ? t(errorMessage) : ""}
                    tag="b3"
                    color="color-eta"
                />
            )}
        </div>
    );
};

export default InputText;
