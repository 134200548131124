import React from "react";
import "./_index.css";
import { getImage } from "./../../../../asset";


interface ImageProps {
    imageRoute: string;
    onClick?: () => void;
    className?:string;
}

/**
 * Primary UI component for user interaction
 */
export const Image = ({ imageRoute, onClick = () => {}, className,...props }: ImageProps) => {
    let imagePath = require("./../../../../asset/" + getImage(imageRoute)); //eslint-disable-line
    return (
        <>
            <div className="image--container">
                {imageRoute && <img src={imagePath} className={className} />}
            </div>
        </>
    );
};
