import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSentRequestInfo: false,
    isLoading: false,
};

export const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {
        clean: (state, action) => {
            return initialState;
        },
        addRequestInfoStatus:(state, action) => {
            const { sentRequestInfo } = action.payload;
            state.isSentRequestInfo = sentRequestInfo;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
    },
});

export const {
    clean,
    startLoading,
    stopLoading,
    addRequestInfoStatus,
} = homeSlice.actions;

export default homeSlice.reducer;
