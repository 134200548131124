export function handleScroll(): void {
    const container = document.getElementById("animatedContainer");
    if (!container) return;

    const position = container.getBoundingClientRect().top;
    const screenHeight = window.innerHeight;

    if (position < screenHeight * 0.99) {
        container.classList.add("show");
    }
}

export function handleScroll2(): void {
    const container = document.getElementById("animatedContainer2");
    if (!container) return;

    const position = container.getBoundingClientRect().top;
    const screenHeight = window.innerHeight;

    if (position < screenHeight * 0.75) {
        container.classList.add("show");
    }
}

export function handleScroll3(): void {
    const container = document.getElementById("animatedContainer3");
    if (!container) return;

    const position = container.getBoundingClientRect().top;
    const screenHeight = window.innerHeight;

    if (position < screenHeight * 0.75) {
        container.classList.add("show");
    }
}

export function handleScroll4(): void {
    const container = document.getElementById("animatedContainer4");
    if (!container) return;

    const position = container.getBoundingClientRect().top;
    const screenHeight = window.innerHeight;

    if (position < screenHeight * 0.75) {
        container.classList.add("show");
    }
}
