import React, { useState } from "react";

import { useHistory } from "react-router-dom";

/*components*/
import Icon from "../Icon";
import Avatar from "../Avatar";
import { Text } from "../Text";
import { getColor } from "./../../../../asset/themes";
import MainLogin from "../../../../asset/Image/logos/main-logo.png";
import { Button } from "../Button";
import { Sidenav } from "../Sidenav";
import "./_index.css";

interface HeaderProps {
    /**
     * is user logged?
     */
    isLogged?: boolean;
    /**
     * show unlogged button?
     */
    showButton?: boolean;
    /**
     * Header text
     */
    label?: string;
    /**
     * Header text color
     */
    labelColor?: string;
    /**
     * change backgroundColor?
     */
    backgroundColor?: string;
    /**
     * change icon color?
     */
    iconColor?: string;
    /**
     * disable left arrow?
     */
    disableLeftArrow?: boolean;

    /**
     * avatar src
     */
    avatar?: string;
    /**
     * button icon
     */
    buttonIcon?: string;
    /**
     * button icon color
     */
    buttonIconColor?: string;
    /**
     * button icon color
     */
    buttonMode?: string;
    /**
     * button icon color
     */
    buttonLabel?: string;
    /**
     * is Home?
     */
    isHome?: boolean;
    className?: string;
    hideLogo?:boolean;

    /**
     * Optional click handler
     */
    onClick?: () => void;
    /**
     * Optional button click handler
     */
    onClickButton?: () => void;


    isContaintNewNotification?: boolean;
}

/**
 * Primary UI component for user interaction
 */
export const Header = ({
    isLogged = false,
    showButton = false,
    backgroundColor = "color-beta",
    label = "",
    disableLeftArrow,
    labelColor = "color-alpha",
    iconColor,
    isHome = false,
    avatar,
    buttonIcon,
    buttonIconColor,
    buttonMode = "primary",
    buttonLabel = "",
    className,
    onClick,
    onClickButton,
    isContaintNewNotification = false,
    hideLogo,
    ...props
}: HeaderProps) => {
    const [isSidenavOpen, setIsSidenavOpen] = useState(false);
    const history = useHistory();

    const redirect = (to: string) => {
        history.push(to);
    };

    const redirectBack = () => {
        history.goBack();
    };

    return (
        <>
            <Sidenav
                showSideMenu={isSidenavOpen}
                hideLogo={hideLogo}
                onClick={(value) => {
                    setIsSidenavOpen(value);
                }}
            />
            <header
                className={`storybook--header-${backgroundColor} ${className}`}
                style={{
                    backgroundColor: getColor(backgroundColor),
                    zIndex: 998,
                }}>
                {!disableLeftArrow && (
                    <Icon
                        icon="icon:arrow-left"
                        name="header-left-icon"
                        iconColor={iconColor}
                        onClick={() => onClick ? onClick() : redirectBack()}
                    />
                )}

                {isHome && (
                    <>
                        <Icon
                            icon="icon:menu"
                            name="header-left-icon header-left-icon-menu"
                            size="big-small"
                            iconColor="color-alpha"
                            onClick={() => {
                                setIsSidenavOpen(true);
                            }}
                        />
                        <div
                            className={[
                                "storybook--header-main-logo-container",
                                `storybook--header-main-logo-container-unlogged`,
                                "storybook--center-elements",
                            ].join(" ")}>
                            <img className={"home-image-logo"} src={MainLogin} />
                            <nav>
                                <ul id="menu" className="storybook--header-nav-menu">
                                    <li><a href="#home">Inicio</a></li>
                                    <li><a href="/aboutUs">Quiénes Somos</a></li>
                                    <li><a href="#contacto">Contacto</a></li>
                                    <li><a href="#paquetes">Paquetes</a></li>
                                </ul>
                            </nav>
                        </div>
                    </>
                )}

                {!isHome && <Text label={label} tag="h4" color={labelColor} />}

                {isLogged && (
                    <div className={['storybook--container-flex','home-logged-components'].join(" ")}>
                        <Avatar
                            borderColor="color-gamma"
                            avatar={avatar}
                            onClick={() => redirect("/profile")}
                        />
                        <div className="storybook--container-flex storybook--container-flex-align-items-center" onClick={() => redirect("/notifications")}>
                            <Icon icon="icon:bell" size="big-small" iconColor="color-alpha"/>
                            {
                              isContaintNewNotification && (<div className="header-has-notifications"></div>)
                            }
                            
                        </div>
                    </div>
                )}
            </header>
        </>
    );
};
