import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    create: {
        reservations: [],
        groups: [],
        selectedReservation: {},
        selectedGroup: {},
        playersPerTeam: 0,
        totalPlayers: 0,
        isSuccessful: false,
        isError: false,
        upcomingMejengas: [],
    },
    selected: {
        idMejenga: 0,
        grupo: {
            nombre: "",
            imagenPefil: "",
            miembros: [],
            idGrupo:""
        },
        reserva: {
            producto: {
                vendedor: {
                    logo: "",
                    idVendedor:""
                }
            },
            sede: {
                nombre: ""
            },
            fechaReserva: "",
            horario: {
                diaSemana: "",
                horaInicio: "",
                horaFin: "",
            }
        },
        creadorMejenga: {
            administrador: false
        },
        asistencias: [],
        calificaciones: [],
        pagos: [],
        teamA: [],
        teamB: [],
        equipoA: "",
        equipoB: "",
        uniformeEquipoA: "",
        uniformeEquipoB: "",
        jugadoresPorEquipo: 0,
    },
    confirmAttendanceSuccess: false,
    confirmPaymentSuccess: false,
    deleteMejengaSuccess: false,
    mejengas: [],
    isLoading: false,
    mejengasInProgress: [],
    upcomingMejengas: [],
};

export const mejengaSlice = createSlice({
    name: "mejenga",
    initialState,
    reducers: {
        addReservations: (state, action) => {
            const { reservations } = action.payload;
            state.create.reservations = reservations;
        },
        addGroups: (state, action) => {
            const { groups } = action.payload;
            state.create.groups = groups;
        },
        addFormValues: (state, action) => {
            const { selectedReservation, selectedGroup, playersPerTeam, totalPlayers } = action.payload;
            state.create.selectedReservation = selectedReservation;
            state.create.selectedGroup = selectedGroup;
            state.create.playersPerTeam = playersPerTeam;
            state.create.totalPlayers = totalPlayers;
        },
        addMejengas: (state, action) => {
            const { mejengas } = action.payload;
            state.mejengas = mejengas;
        },
        addSelected: (state, action) => {
            const { selected } = action.payload;
            state.selected = selected;
        },
        addTeamA: (state, action) => {
            const { teamA } = action.payload;
            state.selected.teamA = teamA;
        },
        addTeamB: (state, action) => {
            const { teamB } = action.payload;
            state.selected.teamB = teamB;
        },
        addTeamsUniform: (state, action) => {
            const { uniformA, uniformB } = action.payload;
            state.selected.uniformeEquipoA = uniformA;
            state.selected.uniformeEquipoB = uniformB;
        },
        isError: (state, action) => {
            state.isLoading = false;
            state.create.isError = true;
            state.create.isSuccessful = false;
        },
        isSuccessfull: (state, action) => {
            const { success } = action.payload;
            state.isLoading = false;
            state.create.isSuccessful = success;
            state.create.isError = false;
        },
        isConfirmAttendanceSuccess: (state, action) => {
            const { success } = action.payload;
            state.isLoading = false;
            state.confirmAttendanceSuccess = success;
            state.create.isError = false;
        },
        isConfirmPaymentSuccess: (state, action) => {
            const { success } = action.payload;
            state.isLoading = false;
            state.confirmPaymentSuccess = success;
            state.create.isError = false;
        },
        isDeleteMejengaSuccess: (state, action) => {
            const { success } = action.payload;
            state.isLoading = false;
            state.deleteMejengaSuccess = success;
            state.create.isError = false;
        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        addMejengasInProgress: (state, action) => {
            const { mejengasInProgress } = action.payload;
            state.mejengas = mejengasInProgress;
        },
        cleanUpcomingMejengas: (state, action) => {
            state.upcomingMejengas = [];
        },
        listUpcomingMejengas: (state, action) => {
            const { mejengas } = action.payload;
            state.upcomingMejengas = mejengas;
        },
    },
});

export const {
    addReservations,
    addGroups,
    addFormValues,
    addMejengas,
    addSelected,
    addTeamA,
    addTeamB,
    addTeamsUniform,
    clean,
    startLoading,
    stopLoading,
    isError,
    isSuccessfull,
    isConfirmAttendanceSuccess,
    isConfirmPaymentSuccess,
    isDeleteMejengaSuccess,
    addMejengasInProgress,
    cleanUpcomingMejengas,
    listUpcomingMejengas
} = mejengaSlice.actions;

export default mejengaSlice.reducer;
