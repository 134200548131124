import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    create: {},
    isLoading: false,
    isSuccess:false,
};


export const reportSuggestionSlice = createSlice({
    name: "reportSuggestion",
    initialState,
    reducers: { 
        isSuccess: (state, action) => {
            state.isLoading = false;
            state.isSuccess = true;        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
    },
});

export const {
    clean,
    startLoading,
    stopLoading,
    isSuccess,
} = reportSuggestionSlice.actions;
export default reportSuggestionSlice.reducer;