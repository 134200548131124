import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    emulatePayment:{},
    isLoading: false,
};

export const emulatePaymentSlice = createSlice({
    name: "emulatePayment",
    initialState,
    reducers: {
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        initEmulatePayment: (state, action) => {
            state.emulatePayment= initialState.emulatePayment;
        },
        setEmulatePayment: (state, action) => {
            const { emulatePayment } = action.payload;
            state.emulatePayment = emulatePayment;
        },
    },
});

export const {
    clean,
    startLoading,
    stopLoading,
    setEmulatePayment,
    initEmulatePayment
} = emulatePaymentSlice.actions;
export default emulatePaymentSlice.reducer;