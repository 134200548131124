import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    alias: "",
    avatar: "",
    birthdate: "",
    email: "",
    id: "",
    idPerson: "",
    names: "",
    surnames: "",
    identificationNumber: "",
    identificationType: "",
    isLoading: false,
    showModal: false,
    errorDescription: "",
};

export const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        addUser: (state, action) => {
            const {
                avatar,
                birthdate,
                id,
                idPerson,
                identificationNumber,
                identificationType,
                names,
                email,
                alias,
                surnames,
            } = action.payload;
            state.avatar = avatar;
            state.names = names;
            state.email = email;
            state.id = id; 
            state.idPerson = idPerson;
            state.alias = alias;
            state.surnames = surnames;
            state.birthdate = birthdate;
            state.identificationNumber = identificationNumber;
            state.identificationType = identificationType;
            state.isLoading = false;
        },
        uploadUserAvatar: (state, action) => {
            const {
                avatar,
            } = action.payload;
            state.avatar = avatar;
            state.isLoading = false;
        },
        changeEmail: (state, action) => {
            state.email = action.payload;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        showModal: (state, action) => {
            state.showModal = true;
            state.errorDescription = action.payload.errorMessage;
        },
        closeModal: (state, action) => {
            state.showModal = false;
            state.errorDescription = "";
        },
    },
});

export const { addUser, uploadUserAvatar, changeEmail, startLoading, stopLoading, showModal, closeModal } =
    userSlice.actions;
export default userSlice.reducer;
