import React, { useEffect } from "react";

import { RootState } from "../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { withTranslation } from "react-i18next";
import parse from 'html-react-parser';

/*Components */
import Loader from "../../react-components/src/Elements/Loader";
import { Header } from "../../react-components/src/Elements/Header";
import { Text } from "../../react-components/src/Elements/Text";
import { loadFAQ } from "../../redux/thunks/contenidoHTMLThunk";
import { binToText } from "../../components/Utils";

/*styles*/
import "./_index.css";

const AboutUs = () => {

    const dispatch = useDispatch();
    const history = useHistory();
    const { language } = useSelector((state: RootState) => state.session);
    const { isLoading,faq } = useSelector((state: RootState) => state.contentHTML);
    
    const redirect = (to: string) => {
        history.push(to);
    };

    const handleRedirectToHome = () => {
        redirect("/");
    }

    useEffect(() => {      
            dispatch(loadFAQ({iso:language,alias:'quienes-somos'}));          
    }, []);

    return(<>   
        <Loader isLoading={isLoading} />
        <Header label="Quiénes somos" onClickButton={handleRedirectToHome} />
        <div className={["faq--container","faq--container-padding"].join(" ")} >
            {faq!==undefined && faq.contenidoBinario!=="" && (<>
            {parse(binToText(faq.contenidoBinario) )}
            </>)}
        </div>
    </>);
}

export default withTranslation()(AboutUs);