import React from "react";
import { useTranslation } from "react-i18next";
import "../../../assets/css/global.css";

interface TextProps {
    /**
     * Text tag
     */
    tag?:
        | "h1"
        | "h2"
        | "h3"
        | "h4"
        | "h5"
        | "h6"
        | "s1"
        | "s2"
        | "s3"
        | "s4"
        | "b1"
        | "b2"
        | "b3"
        | "b4";

    /**
     * change color?
     */
    color?: string;
    /**
     * Text content
     */
    label: string;
    /**
     * Text alignment
     */
    align?: "left" | "center" | "right";
    /**
     * class name
     */
    className?: string;
    /**
     * Optional click handler
     */
    donotTranslate?: boolean;
    onClick?: () => void;
}

/**
 * Primary UI component for user interaction
 */
export const Text = ({
    tag = "s1",
    label,
    align = "left",
    color = "color-alpha",
    className,
    donotTranslate= false,
    ...props
}: TextProps) => {
    const { t } = useTranslation();
    return (
        <p
            className={[
                `storybook--text`,
                `storybook--text-${tag}`,
                `storybook--${color}`,
                className,
            ].join(" ")}
            style={{ textAlign: align }}
            {...props}>
            {donotTranslate ? label : t(label)}
        </p>
    );
};
