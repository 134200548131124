import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notification: {
        id: 0,
        email: 0,
        push: 0,
        sms: 0,
    },
    isLoading: false,
    isAvatarChanged: false,
    isUpdateProfileChanged:false,
    isAliasValid: true,
};

export const profileSlice = createSlice({
    name: "profile",
    initialState,
    reducers: {
        addNotificationSettings: (state, action) => {
            const { email, push, sms, id } = action.payload;
            state.notification.email = email;
            state.notification.push = push;
            state.notification.sms = sms;
            state.notification.id = id;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        updateAvatarChanged: (state, action) => {
            const { avatarChanged } = action.payload;
            state.isAvatarChanged = avatarChanged;
        },
        updateProfileChanged: (state, action) => {
            const { isUpdateProfileChanged } = action.payload;
            state.isUpdateProfileChanged = isUpdateProfileChanged;
        },
        updateAliasValid: (state, action) => {
            const {isAliasValid} = action.payload;
            state.isAliasValid = isAliasValid
        }
    },
});

export const { addNotificationSettings, startLoading, stopLoading, updateAvatarChanged,updateProfileChanged, updateAliasValid } = profileSlice.actions;
export default profileSlice.reducer;
