import React from "react";
import { getColor } from "../../../../asset/themes";
import "./_index.css";

interface DividerProps {
    /**
     * Button mode
     */
    mode?: "dashed" | "solid";
    /**
     * change color?
     */
    color?: string;
    className?: string;
}

/**
 * Primary UI component for user interaction
 */
export const Divider = ({ mode = "solid", color = "color-alpha",className, ...props }: DividerProps) => {
    return (
        <hr
            className={[`storybook--hr`, `storybook--hr-${mode}`, className].join(" ")}
            style={{ borderColor: getColor(color) }}
            {...props}></hr>
    );
};
