import React from "react";

import { Text } from "../../Text";
import { Button } from "../../Button";
import { Image } from "../../Image";

const Step2 = ({onFinish}:any) => {
    return (
        <div>
            <Text tag="h2" label="onboarding..swiper.step2.title.text" align="center"/>
            <div className="storybook--center-elements">
                <Image imageRoute="image:mejenga-empty" className={["mejenga--image-empty"].join(" ")} />
            </div>
            <Text tag="b2" label="onboarding..swiper.step2.description.text" align="center" />
        </div>
    );
};

export default Step2;
