import React, {useState} from "react";

import { Card } from "../../../react-components/src/Elements/Card";
import Icon from "../../../react-components/src/Elements/Icon";
import { Text } from "../../../react-components/src/Elements/Text";

/*styles*/
import "../_index.css";
import { Button } from "../../../react-components/src/Elements/Button";
import Modal from "../../../react-components/src/Elements/Modal";

const BASIC_PACKAGE = [
    "Dashboard de administración",
    "Registro de una sede",
    "2 usuarios administradores",
    "2 métodos de pago habilitados",
    "2 imágenes de la sede",
    "Soporte técnico (Horario oficina)",
    "1 mes de prueba gratis",
    "4% por reserva",
];
const PREMIUM_PACKAGE = [
    "Dashboard de administración",
    "Registro ilimitado de sedes",
    "4 usuarios administradores",
    "Todos los métodos de pago habilitados",
    "Creación y gestión de torneos",
    "5 imágenes de la sede",
    "Soporte técnico 24/7",
    "1 mes de prueba gratis",
    "4% por reserva",
];

const PREMIUM_PACKAGE_REQUEST = "Hola! Estoy interesado en el Paquete Premium. Me gustaría conocer más";
const BASIC_PACKAGE_REQUEST = "Hola! Estoy interesado en el Paquete Básico. Me gustaría conocer más";

export const HomePackages = () => {
    const [showRedirectPackageModal, setShowRedirectPackageModal] = useState(false);
    const [selectPackage, setSelectedPackage] = useState("");

    const redirectExternal = (to: string) => {
        window.open(to, "_blank")
    };

    const handleSelectedPackage = (type:string) => {
        setSelectedPackage(type);
        setShowRedirectPackageModal(true);
    }
    
    const handleRedirectPackageRequest = () => {
        setShowRedirectPackageModal(false);
        redirectExternal(`https://wa.me/50683238129?text=${selectPackage}`)
    }

    return (
        <>
            <div id="paquetes">
                <Text
                    label="Elige el paquete perfecto para tu centro deportivo"
                    align="center"
                    tag="h1"
                    className="storybook--margin-bottom-md storybook--color-phi text--line-height-md"
                />
                <div className="storybook--center-elements storybook--margin-bottom-md">
                    <div
                        className="storybook--footer-default-padding storybook--container-flex storybook--container-flex-space-between home--packages-container"
                        style={{ width: "50rem" }}>
                        <Card className="home--packages-container-package" backgroundColor="color-beta">
                            <Text
                                label="Paquete Básico"
                                align="center"
                                color="color-gamma"
                                tag="h3"
                                className="storybook--margin-bottom-xs"
                            />
                            <Button
                                mode="chip-warning"
                                size="chip"
                                label="AHORRA 0%"
                                className="storybook--general-default-padding storybook--margin-bottom-xs"
                            />
                            <div className="storybook--container-flex storybook--general-default-padding">
                                <Text label="$20" color="color-alpha" tag="h1" donotTranslate />
                                <Text
                                    label="/mes"
                                    color="color-alpha"
                                    tag="s2"
                                    className="home--packages-price-period"
                                    donotTranslate
                                />
                            </div>
                            <Text
                                label="Con un período de 1 año. Pagas $20/mes hoy. Renueva por $20/mes."
                                tag="s2"
                                className="storybook--general-default-padding text--line-height-xs storybook--color-phi storybook--margin-bottom-xs"
                            />
                            <Button
                                label="Comprar"
                                mode={"primary-outline"}
                                className="home--packages--package-button"
                                onClick={() => handleSelectedPackage(BASIC_PACKAGE_REQUEST)}
                            />
                            <div className="storybook--footer-default-padding">
                                {BASIC_PACKAGE.map((plan: string) => (
                                    <Icon
                                        icon="icon:check"
                                        iconColor="color-gamma"
                                        label={plan}
                                        labelPosition="right"
                                    />
                                ))}
                            </div>
                        </Card>
                        <Card className="home--packages-container-package" backgroundColor="color-beta">
                            <Text
                                label="Paquete Premium"
                                align="center"
                                color="color-gamma"
                                tag="h3"
                                className="storybook--margin-bottom-xs"
                            />
                            <div className="storybook--general-default-padding storybook--container-flex storybook--margin-bottom-xs">
                                <Button mode="chip-warning" size="chip" label="AHORRA 30%" />
                                <Button mode="chip-success" size="chip" label="Recomendado" />
                            </div>
                            <div className="storybook--container-flex storybook--general-default-padding">
                                <Text label="$50" color="color-alpha" tag="h1" className="" donotTranslate />
                                <Text
                                    label="/mes"
                                    color="color-alpha"
                                    tag="s2"
                                    className="home--packages-price-period"
                                    donotTranslate
                                />
                            </div>
                            <Text
                                label="Con un período de 1 año. Pagas $50/mes hoy. Renueva por $65/mes."
                                tag="s2"
                                className="storybook--general-default-padding text--line-height-xs storybook--color-phi storybook--margin-bottom-xs"
                            />
                            <Button
                                label="Comprar"
                                mode={"primary-outline"}
                                className="home--packages--package-button"
                                onClick={() => handleSelectedPackage(PREMIUM_PACKAGE_REQUEST)}
                            />
                            <div className="storybook--footer-default-padding">
                                {PREMIUM_PACKAGE.map((plan: string) => (
                                    <Icon
                                        icon="icon:check"
                                        iconColor="color-gamma"
                                        label={plan}
                                        labelPosition="right"
                                    />
                                ))}
                            </div>
                        </Card>
                    </div>
                </div>
            </div>
            {showRedirectPackageModal &&
                <Modal mode="default" description="Te redireccionaremos con nuestro vendedor" icon="icon:headphones" >
                    <Button label="ui.button.accept" mode="primary" onClick={() => handleRedirectPackageRequest()} />
                </Modal>
            }
        </>
    );
};
