import React from "react";

import { Text } from "../../Text";
import { Button } from "../../Button";
import { Image } from "../../Image";

const Step4 = ({onFinish}:any) => {
    return (
        <div>
            <Text tag="h2" label="onboarding..swiper.step4.title.text" align="center"/>
            <div className="storybook--center-elements">
                <Image imageRoute="image:create-mejenga" className={["swiper--image4"].join(" ")} />
            </div>
            <Text tag="b2" label="onboarding..swiper.step4.description.text" align="center" />
            <div className="storybook--footer-default-padding">
                <Button label="Aceptar" mode="primary" onClick={() => onFinish()}/>
            </div>
        </div>
    );
};

export default Step4;
