import API from "./api";

import { constantes } from "../../components/constantes";
import {addDevelopedBy, addFAQ, addPrivacyPolicy, addTermsAndConditions,startLoading,stopLoading} from "../reducers/contentHTMLSlice";

export const loadTermsAndConditions = ({ token,iso,alias }: any) => {
    return async (dispatch: any) => {   
        dispatch(startLoading({}));    
        try {
            const response = await API.get(`/contenidoHtml/data/${iso}/${alias}`, {
                headers: {
                    ...constantes.axiosConfig
                },
            });
            if (response.data.estatus !== constantes.estatusOKAServ) {
                throw new Error(response.data.errores[0].mensajeError);
            }
            dispatch(addTermsAndConditions({ termsAndConditions: response.data.data }));
            dispatch(stopLoading({}));
        } catch (error) {
            dispatch(stopLoading({}));
        }
    };
};

export const loadContentDevelopedBy = ({ token,iso,alias }: any) => {
    return async (dispatch: any) => {   
        dispatch(startLoading({}));    
        try {
            const response = await API.get(`/contenidoHtml/data/${iso}/${alias}`, {
                headers: {
                    ...constantes.axiosConfig
                },
            });
            if (response.data.estatus !== constantes.estatusOKAServ) {
                throw new Error(response.data.errores[0].mensajeError);
            }
            dispatch(addDevelopedBy({ developedBy: response.data.data }));
            dispatch(stopLoading({}));
        } catch (error) {
            dispatch(stopLoading({}));
        }
    };
};

export const loadFAQ = ({iso,alias }: any) => {
    return async (dispatch: any) => {   
        dispatch(startLoading({}));    
        try {
            const response = await API.get(`/contenidoHtml/data/${iso}/${alias}`, {
                headers: {
                    ...constantes.axiosConfig
                },
            });
            if (response.data.estatus !== constantes.estatusOKAServ) {
                throw new Error(response.data.errores[0].mensajeError);
            }
            dispatch(addFAQ({ faq: response.data.data }));
            dispatch(stopLoading({}));
        } catch (error) {
            dispatch(stopLoading({}));
        }
    };
};

export const loadPrivacyPolicy = ({iso,alias }: any) => {
    return async (dispatch: any) => {   
        dispatch(startLoading({}));    
        try {
            const response = await API.get(`/contenidoHtml/data/${iso}/${alias}`, {
                headers: {
                    ...constantes.axiosConfig
                },
            });
            if (response.data.estatus !== constantes.estatusOKAServ) {
                throw new Error(response.data.errores[0].mensajeError);
            }
            dispatch(addPrivacyPolicy({ privacyPolicy: response.data.data }));
            dispatch(stopLoading({}));
        } catch (error) {
            dispatch(stopLoading({}));
        }
    };
};