import React from "react";
import DefaultAvatar from "../../../../asset/Image/defaultAvatar.png";
import { getColor } from "../../../../asset/themes";
import "./_index.css";

class Avatar extends React.Component<{
    avatar?: string;
    size?: "small" | "medium" | "big";
    borderColor?: string;
    className?: string;
    onClick?: () => void;
    imageDefaultAvatar?: string;
}> {
    constructor(props: any) {
        super(props);
    }

    render() {
        const {
            avatar,
            size = "small",
            borderColor = "color-beta",
            className,
            onClick,
            imageDefaultAvatar
        } = this.props;
        let iconPath = avatar ? avatar : DefaultAvatar; //eslint-disable-line
        let iconDefaultPath = imageDefaultAvatar?imageDefaultAvatar:DefaultAvatar //eslint-disable-line
        return (
            <button
                onClick={onClick}
                className={["storybook--avatar", `storybook--avatar-${size}`, className].join(" ")}
                style={{
                    borderColor: getColor(borderColor),
                    backgroundImage: `url("${iconPath}"), url(${iconDefaultPath})`,
                }}></button>
        );
    }
}

export default Avatar;
