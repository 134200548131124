import { combineReducers } from "redux";

import userSlice from "./userSlice";
import sessionSlice from "./sessionSlice";
import registerSlice from "./registerSlice";
import secondFactorSlice from "./secondFactorSlice";
import recoverySlice from "./recoverySlice";
import profileSlice from "./profileSlice";
import groupSlice from "./groupSlice";
import sportcenterSlice from "./sportcenterSlice";
import notificationSlice from "./notificationSlice";
import reservationSlice from "./reservationSlice";
import mejengaSlice from "./mejengaSlice";
import contentHTMLSlice from "./contentHTMLSlice";
import emulatePaymentSlice from "./emulatePaymentSlice";
import reportSuggestionSlice from "./reportSuggestionSlice";
import calificationPlatformSlice from "./calificationPlatformSlice";
import calificationUserSlice from "./calificationUserSlice";
import paymentGatewaySlice from "./paymentGatewaySlice";
import homeSlice from "./homeSlice";

const reducers = combineReducers({
    register: registerSlice,
    session: sessionSlice,
    user: userSlice,
    secondFactor: secondFactorSlice,
    recovery: recoverySlice,
    profile: profileSlice,
    group: groupSlice,
    sportcenter: sportcenterSlice,
    notification: notificationSlice,
    reservation: reservationSlice,
    mejenga: mejengaSlice,
    contentHTML: contentHTMLSlice,
    emulatePayment:emulatePaymentSlice,
    reportSuggestion:reportSuggestionSlice,
    calificationPlatform:calificationPlatformSlice,
    calificationUser: calificationUserSlice,
    paymentGateway: paymentGatewaySlice,
    home: homeSlice,
});

export default reducers;
