import React, { Component } from 'react'

import logo from '../../asset/Image/logos/main-logo.png';
import noAvalible from '../../asset/Image/no-avalible.png';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { withTranslation } from 'react-i18next';

/*styles*/
import "./_NotAvailable.css";

class NotAvailable extends Component {

  reintentar = () =>{                 
    window.location.href = "/";
  }

  render () {
    return (     
        <Container fluid="md" className="not-available-margin">
        <img src={logo} alt="logo" className="not-available-image-logo p-1"/>
        <Row className="justify-content-md-center p-1">                
        <Card style={{"width":"20rem"}}>                                
                <Card.Body>
                        <Col className="col text-center">
                            <img src={noAvalible} alt="logo" className="p-1"/>
                            <h2  className="not-available-margin-h2">En este momento la plataforma no se encuentra disponible.</h2>                            
                            <p  className="not-available-margin-p">Favor comuniquese con el administrador de la plataforma si requiere mayor información.</p> 
                        </Col> 
                </Card.Body>
                </Card>  
        </Row>                                         
        </Container>
    )
  }
}

export default withTranslation()(NotAvailable);