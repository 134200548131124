import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import store from "./redux/store";
import { Provider } from "react-redux";
import { getI18n } from "./services/traduccionServ";
import { setI18n } from "./redux/thunks/sessionThunk";
import { initReactI18next } from 'react-i18next';
import i18n from "i18next";
import NotAvailable from "./pages/notAvailable/NotAvailable";

let initial18n = async () => {
    try {
        var esData = null;
        var enData = null;
        setI18n(undefined);
        if (typeof window !== "undefined") {
            esData = await getI18n("es");
        }
        if (typeof window !== "undefined") {
            enData = await getI18n("en");
        }
        return { esData, enData };
    } catch (error) {
    }
};

let importI18n = async () => {
    if (typeof window !== "undefined") {
        await import("./i18n");
        // console.log("test index 1");
    }
};

initial18n().then((data) => {

    if(data===undefined){
        ReactDOM.render(
            <React.StrictMode>
                <Provider store={store}>
                   <NotAvailable/>
                </Provider>
            </React.StrictMode>,
            document.getElementById("root"),
        );
    }else{

        var resources = {
            es: data.esData.data,
            en: data.enData.data,
        };

        i18n.use(initReactI18next).init({
            resources: {
                es: {
                    translation: data.esData.data,
                },
                en: {
                    translation: data.enData.data,
                },
            },
            lng: "es", // Default language
            fallbackLng: "es", // Fallback language
            interpolation: {
                escapeValue: false, // React already sanitizes its output
            },
        });
        
        // console.log("getTokens resources",resources);
        setI18n(resources);
        importI18n().then(() => {
            //console.log("test index 2");
            ReactDOM.render(
                <React.StrictMode>
                    <Provider store={store}>
                        <App/>
                    </Provider>
                </React.StrictMode>,
                document.getElementById("root"),
            );
        });
    }
},(error)=>{
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
               <NotAvailable/>
            </Provider>
        </React.StrictMode>,
        document.getElementById("root"),
    );
});
