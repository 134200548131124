import React, {useState} from "react";

import { RootState } from "../../../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { addRequestInfoStatus } from "../../../redux/reducers/homeSlice";
import { sendRequestInfo } from "../../../redux/thunks/homeThunk";

import { Card } from "../../../react-components/src/Elements/Card";
import Icon from "../../../react-components/src/Elements/Icon";
import { Text } from "../../../react-components/src/Elements/Text";

import Logo from "../../../asset/Image/logos/main-logo.png";

/*styles*/
import "../_index.css";
import { Form, Formik } from "formik";
import InputText from "../../../react-components/src/Elements/Form/InputText";
import { Button } from "../../../react-components/src/Elements/Button";
import { homeInformationFormInitValues, homeInformationFormSchema } from "../../../schemas/homeSchema";
import Loader from "../../../react-components/src/Elements/Loader";
import Modal from "../../../react-components/src/Elements/Modal";

export const HomeFooter = () => {
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const history = useHistory();
    const {isLoading, isSentRequestInfo} = useSelector((state: RootState) => state.home);

    const redirect = (to: string) => {
        history.push(to);
    };

    const redirectExternal = (to: string) => {
        window.open(to, "_blank")
    };

    const handleSubmit = (values: typeof homeInformationFormInitValues, formikBag: any) => {
        dispatch(sendRequestInfo({email: values.email}));
    }

    const handleRequestInfoSuccess = () => {
        dispatch(addRequestInfoStatus({sentRequestInfo: false}));
    }

    return (
        <>
            <Loader isLoading={isLoading} />
            <div className="storybook--center-elements storybook--footer-default-padding home--footer-container">
                <Card backgroundColor="color-beta" className="footer-card-height">
                    <div>
                        <img src={Logo} alt="Vamos futbol" className="store-icon storybook--margin-bottom-sm" />
                        <Text
                            label="Nuestra misión es digitalizar y optimizar la gestión de centros deportivos, brindando una experiencia fácil, rápida y eficiente tanto para jugadores como para administradores, asegurando mayor organización y aprovechamiento del tiempo"
                            tag="s2"
                            className="text--line-height storybook--color-phi"
                        />
                    </div>
                </Card>
                <Card backgroundColor="color-beta" className="footer-card-height" id="contacto">
                    <Text
                        label="Contacto"
                        align="center"
                        color="color-alpha"
                        tag="h3"
                        className="storybook--margin-bottom-sm"
                    />
                    <div className="storybook--footer-default-padding storybook--margin-bottom-sm">
                        <Icon
                            className="footer-card-contact"
                            label="ventas@vamos-futbol.com"
                            icon="icon:mail"
                            labelPosition="right"
                        />
                        <Icon
                            className="footer-card-contact"
                            label="(+506) 8323 8129"
                            icon="icon:headphones"
                            labelPosition="right"
                        />
                        <Icon
                            className="footer-card-contact"
                            label="Avenida Real de Pereira, Condominio Catalano, Casa 4."
                            icon="icon:map-pin"
                            labelPosition="right"
                        />
                    </div>
                    <Text
                        label="¿Requiere más información?"
                        color="color-gamma"
                        tag="h5"
                        align="center"
                        className="storybook--margin-bottom-sm"
                    />
                    <Formik initialValues={homeInformationFormInitValues} validationSchema={homeInformationFormSchema(t)} onSubmit={(values, formikBag) => handleSubmit(values, formikBag)}>
                        {(props) => <Form>
                            <InputText
                                label="label.register.form.email"
                                name="email"
                                placeholder="placeholder.recovery.form.email"
                            />
                            <Button
                                isSubmit
                                label="ui.button.accept"
                                mode={!props.isValid || !props.dirty ? "disabled" : "primary"}
                                isDisabled={!props.isValid || !props.dirty}
                            />
                        </Form>}
                    </Formik>
                </Card>
                <Card backgroundColor="color-beta" className="footer-card-height">
                    <Text
                        label="Información adicional"
                        align="center"
                        color="color-alpha"
                        tag="h3"
                        className="storybook--margin-bottom-sm"
                    />
                    <div>
                        <Text
                            label="Preguntas frecuentes"
                            className="footer-link storybook--margin-bottom-sm"
                            align="center"
                            onClick={() => redirect("faq")}
                        />
                        <Text
                            label="Términos y condiciones"
                            className="footer-link storybook--margin-bottom-sm"
                            align="center"
                            onClick={() => redirect("termsConditions")}
                        />
                        <Text
                            label="Política de privacidad"
                            className="footer-link storybook--margin-bottom-sm"
                            align="center"
                            onClick={() => redirect("privacyPolicy")}
                        />
                        <Text
                            label="Nuestros socios"
                            className="footer-link storybook--margin-bottom-sm"
                            align="center"
                            onClick={() => redirect("partners")}
                        />
                    </div>
                </Card>
                <Card backgroundColor="color-beta" className="footer-card-height">
                    <Text
                        label="Descarga la app"
                        align="center"
                        color="color-alpha"
                        tag="h3"
                        className="storybook--margin-bottom-sm"
                    />
                    <div className="storybook--center-elements storybook--margin-bottom-sm">
                        <a
                            href="https://play.google.com/store/apps/details?id=tas.vamosfutbol.com&hl=es_CR"
                            className="store-button">
                            <img
                                src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                                alt="Google Play"
                                className="store-icon"
                            />
                        </a>

                        <a href="https://apps.apple.com/cr/app/vamos-futbol/id6740782681?l=en-GB" className="store-button">
                            <img
                                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                                alt="App Store"
                                className="store-icon"
                            />
                        </a>
                    </div>
                    <Text
                        label="Redes sociales"
                        align="center"
                        color="color-alpha"
                        tag="h3"
                        className="storybook--margin-bottom-sm"
                    />
                    <div className="storybook--center-elements storybook--container-flex-gap-sm">
                        <img
                            className="storybook--general-cursor-pointer"
                            width="32"
                            height="32"
                            src="https://img.icons8.com/?size=100&id=uLWV5A9vXIPu&format=png&color=000000"
                            alt="facebook-logo"
                        />
                        <img
                            className="storybook--general-cursor-pointer"
                            width="32"
                            height="32"
                            src="https://img.icons8.com/?size=100&id=9a46bTk3awwI&format=png&color=000000"
                            alt="youtube-logo"
                        />
                        <img
                            className="storybook--general-cursor-pointer"
                            width="32"
                            height="32"
                            src="https://img.icons8.com/?size=100&id=16713&format=png&color=000000"
                            alt="whatsapp-logo"
                            onClick={() => redirectExternal("https://wa.me/50683238129")}
                        />
                    </div>
                </Card>
            </div>
            <div>
                <Text
                    label="Copyright © 2025 Plataforma Vamos futbol. Derechos reservados"
                    align="center"
                    tag="s2"
                    className="storybook--color-phi"
                />
            </div>
            {isSentRequestInfo &&
                <Modal mode="success" description="Solicitud enviada correctamente" icon="icon:check-circle" >
                    <Button label="ui.button.accept" mode="primary" onClick={() => handleRequestInfoSuccess()} />
                </Modal>
            }
        </>
    );
};
