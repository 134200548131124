const Colors: { [key: string]: any } = {
    "color-alpha": "#404040",
    "color-beta": "#FFFFFF",
    "color-gamma": "#FF005C",
    "color-delta": "#AA003D",
    "color-epsilon": "#AAB1BC",
    "color-psi": "#F1F2F8",
    "color-eta": "#FF000F",
    "color-sigma": "#43D961",
    "color-tau": "#DDFFE6",
    "color-iota": "#FF9D00",
    "color-phi": "#6E6E6E",
    "color-omega": "#F1F2F8",
    "color-zeta": "#FAFBFF",
    "color-kappa": "#D9D9D9",
    "color-theta": "#075B32",
    "color-mu": "#F2E595",
};

const ModalIconColors: { [key: string]: any } = {
    error: "color-eta",
    success: "color-sigma",
    warning: "color-iota",
    default: "color-alpha",
};

export const getColor = (color: string) => {
    return Colors[color];
};

export const getModalColor = (modalMode: string) => {
    return ModalIconColors[modalMode];
};
