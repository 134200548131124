import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    termsAndConditions:{contenidoBinario:""},
    developedBy:{contenidoBinario:""},
    faq:{contenidoBinario:""},
    privacyPolicy:{contenidoBinario:""},
    isLoading: false,
};

export const contentHTMLSlice = createSlice({
    name: "contentHTML",
    initialState,
    reducers: {
        addTermsAndConditions: (state, action) => {
            const { termsAndConditions } = action.payload;
            state.termsAndConditions = termsAndConditions;
        },
        addDevelopedBy: (state, action) => {
            const { developedBy } = action.payload;
            state.developedBy = developedBy;
        },
        addFAQ: (state, action) => {
            const { faq } = action.payload;
            state.faq = faq;
        },
        addPrivacyPolicy: (state, action) => {
            const { privacyPolicy } = action.payload;
            state.privacyPolicy = privacyPolicy;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
    },
});

export const {
    addTermsAndConditions,
    addDevelopedBy,
    addFAQ,
    addPrivacyPolicy,
    startLoading,
    stopLoading,
} = contentHTMLSlice.actions;

export default contentHTMLSlice.reducer;
