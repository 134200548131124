import React, { useRef, useState } from "react";

// https://swiperjs.com/react

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper/modules";

import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

import "./_index.css";

export const Onboarding = ({ onFinish }: any) => {
    return (
        <div className={["storybook--center-elements", "onboarding--container"].join(" ")}>
            <Swiper pagination modules={[Pagination]} className="mySwiper">
                <SwiperSlide>
                    <Step1 />
                </SwiperSlide>
                <SwiperSlide>
                    <Step2 />
                </SwiperSlide>
                <SwiperSlide>
                    <Step3/>
                </SwiperSlide>
                <SwiperSlide>
                    <Step4 onFinish={onFinish}/>
                </SwiperSlide>
            </Swiper>
        </div>
    );
};

export default Onboarding;
