import { createSlice } from "@reduxjs/toolkit";
import { Type as GroupType} from "../namespaces/group"

const initialState = {
    groupMembers:[],
    name: "",
    avatar: "",
    myGroups: [],
    selected: {
        nombre: "",
        miembros: [{administrador: false}],
        imagePerfilUTF: "",
        idGrupo:"",
    },
    create: {
        avatar: "",
        searchUsers: [],
        tempGroupMembers: [],
        isSuccess: false,
        isError: false,
    },
    isLoading: false,
    isUpdated: false,
};

export const groupSlice = createSlice({
    name: "group",
    initialState,
    reducers: {
        addSearchUsers: (state, action) => {
            const { users } = action.payload;
            state.create.searchUsers = users;
        },
        addTempGroupMembers: (state, action) => {
            const { members } = action.payload;
            state.create.tempGroupMembers = members;
        },
        addGroupMembers: (state, action) => {
            const { members } = action.payload;
            state.groupMembers = members;
        },
        addMyGroups: (state, action) => {
            const { groups } = action.payload;
            state.myGroups = groups;
        },
        addSelected: (state, action) => {
            const { selected } = action.payload;
            state.selected = selected;
        },
        updateSelectedMembers: (state, action) => {
            const { members } = action.payload;
            state.selected.miembros = members;
        },
        cleanSearchUsers: (state, action) => {
            state.create.searchUsers = [];
        },
        clean: (state, action) => {
            return initialState;
        },
        isCreateSuccess: (state, action) => {
            const { success } = action.payload;
            state.create.isSuccess = success;
        },
        isCreateError: (state, action) => {
            const { error } = action.payload;
            state.create.isError = error;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        isGroupUpdated: (state, action) => {
            const { updated } = action.payload;
            state.isUpdated = updated;
        },
    },
});

export const { addSearchUsers, addTempGroupMembers, addGroupMembers, addMyGroups, addSelected, updateSelectedMembers, clean, cleanSearchUsers, startLoading, stopLoading, isGroupUpdated, isCreateSuccess, isCreateError } = groupSlice.actions;
export default groupSlice.reducer;
