import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    sportcenters:[],
    name: "",
    selected: {
        nombre: "",
        miembros: [{administrador: false}],
        logo: "",
        logoUTF: "",
        productos: [],
        paymentTypes: [],
    },
    spacesReservedLoaded: false,
    spacesReserved: [],
    isLoading: false,
};

export const sportcenterSlice = createSlice({
    name: "sportcenter",
    initialState,
    reducers: {
        addSportcenters: (state, action) => {
            const { sportcenters } = action.payload;
            state.sportcenters = sportcenters;
        },
        addSelected: (state, action) => {
            const { selected } = action.payload;
            state.selected = selected;
        },
        addSelectedProducts: (state, action) => {
            const { products } = action.payload;
            state.selected.productos = products;
        },
        addSpacesReserved: (state, action) => {
            const { spacesReserved } = action.payload;
            state.spacesReservedLoaded = true;
            state.spacesReserved = spacesReserved;
        },
        addPaymentTypes: (state, action) => {
            const { paymentTypes } = action.payload;
            state.selected.paymentTypes = paymentTypes;
        },
        clearSpacesReserved: (state, action) => {
            state.spacesReservedLoaded = false;
            state.spacesReserved = [];
        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
    },
});

export const { addSelected, clean, addSportcenters, startLoading, stopLoading,addSelectedProducts, addSpacesReserved, clearSpacesReserved, addPaymentTypes } = sportcenterSlice.actions;
export default sportcenterSlice.reducer;
