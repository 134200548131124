import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    notifications:[],
    selected: {
        contenido:"",
        hashNotificacion:"",
        contenidoHTML:"",
        fechaEnvio:"",
        asunto:"",
    },
    isLoading: false,
    checkNewNotification:false,
};

export const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        addNotifications: (state, action) => {
            const { notifications } = action.payload;
            state.notifications = notifications;
        },
        addSelected: (state, action) => {
            const { selected } = action.payload;
            state.selected = selected;
        },
        clean: (state, action) => {
            return initialState;
        },
        startLoading: (state, action) => {
            state.isLoading = true;
        },
        stopLoading: (state, action) => {
            state.isLoading = false;
        },
        checkNewNotifications:(state, action)=>{
            const { checkNewNotification } = action.payload;
            state.checkNewNotification=checkNewNotification;
        }
    },
});

export const { addSelected, clean, addNotifications, startLoading, stopLoading,checkNewNotifications } = notificationSlice.actions;
export default notificationSlice.reducer;
