import React from "react";

import reservacionesImage from "../../../asset/Image/reservaciones_app.png";
import equiposImage from "../../../asset/Image/equipos_app.png";
import analisisCanchasImage from "../../../asset/Image/analisis_canchas_app.png";
import championshipImage from "../../../asset/Image/championship.jpg";

/*styles*/
import "../_index.css";
import { getColor } from "../../../asset/themes";

export const HomeImages = () => {
    return (
        <>
            <div className={"home"}>
                <div className="storybook--center-elements">
                    <div className="home--images-container" id="animatedContainer">
                        <div className="text-section border-right-text">
                            <h2 style={{ color: getColor("color-gamma") }}>Optimiza y mejora la gestión de reservas</h2>
                            <p>
                                Con un sistema intuitivo, los centros deportivos pueden recibir, organizar y confirmar
                                reservas en segundos, reduciendo errores y maximizando la ocupación de sus canchas.
                            </p>
                        </div>
                        <div className="image-section test storybook--center-elements">
                            <img src={reservacionesImage} alt="Imagen de ejemplo" />
                        </div>
                    </div>
                </div>
                <div className="storybook--center-elements">
                    <div className="home--images-container" id="animatedContainer2">
                        <div className="image-section storybook--center-elements">
                            <img src={championshipImage} alt="Imagen de ejemplo" />
                        </div>
                        <div className="text-section border-left-text">
                            <h2 style={{ color: getColor("color-gamma") }}>Torneos</h2>
                            <p>
                                Impulsa la competitividad en tu centro deportivo con la creación de torneos
                                personalizados. Ofrece a tus jugadores la posibilidad de participar en ligas o
                                eliminatorias con distintos formatos, adaptados a sus necesidades. Simplifica la
                                organización, gestiona equipos y partidos de forma eficiente, y brinda una experiencia
                                profesional a todos los participantes.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="storybook--center-elements">
                    <div className="home--images-container" id="animatedContainer3">
                        <div className="text-section border-right-text">
                            <h2 style={{ color: getColor("color-gamma") }}>Analiza y maximiza el uso de tus canchas</h2>
                            <p>
                                Nuestro sistema permite a los centros deportivos analizar el uso de sus canchas en
                                tiempo real, identificando cuáles son las más reservadas, en qué horarios hay mayor
                                demanda y qué días tienen menor ocupación. Con estos datos, podrán optimizar la
                                disponibilidad, ajustar precios estratégicamente y mejorar la experiencia de sus
                                clientes, maximizando así la rentabilidad de sus instalaciones.
                            </p>
                        </div>
                        <div className="image-section storybook--center-elements">
                            <img src={analisisCanchasImage} alt="Imagen de ejemplo" />
                        </div>
                    </div>
                </div>
                <div className="storybook--center-elements">
                    <div className="home--images-container" id="animatedContainer4">
                        <div className="image-section storybook--center-elements">
                            <img src={equiposImage} alt="Imagen de ejemplo" />
                        </div>
                        <div className="text-section border-left-text">
                            <h2 style={{ color: getColor("color-gamma") }}>Potenciar equipos</h2>
                            <p>
                                Los jugadores podrán crear y gestionar sus propios equipos dentro de la plataforma,
                                organizando partidos, llevando un registro de su rendimiento y facilitando la
                                comunicación con sus compañeros. Una herramienta adicional que mejora la experiencia y
                                la competitividad en cada juego.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};
