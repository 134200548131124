import API from "./api";

import {startLoading,stopLoading, addRequestInfoStatus} from "../reducers/homeSlice";

import { constantes } from "../../components/constantes";

export const sendRequestInfo = ({ email }: any) => {
    return async (dispatch: any) => {   
        dispatch(startLoading({}));    
        try {
            const response = await API.get(`/usuario/solicitudInformacionPlataforma/${email}`, {
                headers: {
                    ...constantes.axiosConfig
                },
            });
            if (response.data.estatus !== constantes.estatusOKAServ) {
                throw new Error(response.data.errores[0].mensajeError);
            }
            dispatch(addRequestInfoStatus({ sentRequestInfo: true }));
            dispatch(stopLoading({}));
        } catch (error) {
            dispatch(stopLoading({}));
        }
    };
};